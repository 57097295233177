import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

/**
 * Sends feedback.
 * @param accessToken - The access token for authorization.
 * @param feedback - The feedback text.
 * @param fileList - The list of files to attach.
 * @returns The response from the server or an error.
 */
async function sendFeedback(accessToken: string, feedback: string, fileList: any[]) {
    try {
        const files = fileList.map((file) => {
            return {
                path: file.urlBase64,
            }
        });
        const data = {
            feedback: feedback,
            file: files
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/feedback`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

export { sendFeedback };