import axios from 'axios';

/**
 * Logs in the user.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<any>} - The response data.
 */
const loginUser = async (email: string, password: string) => {
    try {
        const data = JSON.stringify({email, password}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/login`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true
            }
        );
        return response;
    } catch (error: any) {
        throw error;
    }
}

/**
 * Registers a new user.
 * @param {string} firstname - The user's first name.
 * @param {string} username - The user's username.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<any>} - The response data.
 */
const registerUser = async (firstname: string, username: string, email: string, password: string) => {
    try {
        const data = JSON.stringify({firstname, username, email, password}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/register`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        throw error;
    }
}

/**
 * Logs out the user.
 * @returns {Promise<void>}
 */
const logout = async () => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`,
      {},
      {
        withCredentials: true,
      }
    );
    localStorage.clear();
  } catch (error) {
    throw error;
  }
}

axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 401 && error.response.data.msg === "No token, authorization denied") {
          const tokenRefreshed = await needNewToken();
          if (tokenRefreshed) {
              const originalRequest = error.config;

              return axios(originalRequest);
          }
      }
      return Promise.reject(error);
    },
);

/**
 * Checks the login status of the user.
 * @returns {Promise<boolean>} - True if the user is logged in, false otherwise.
 */
const checkLoginStatus = async () => {
  try {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/auth/verify`, {
        withCredentials: true,
      })
      .then((response) => {
      });
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Refreshes the user's token if needed.
 * @returns {Promise<boolean>} - True if the token was refreshed, false otherwise.
 */
const needNewToken = async () => {
  try {
    await axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
      withCredentials: true,
    });
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Verifies the user's login status and refreshes the token if needed.
 * @returns {Promise<boolean>} - True if the user is logged in or the token was refreshed, false otherwise.
 */
const verifyLogin = async () => {
  const isConnected = await checkLoginStatus();

  if (!isConnected) {
    return await needNewToken();
  } else {
    return true;
  }
};

export { loginUser, registerUser, verifyLogin, logout };