import React, { createContext } from 'react';

interface AnimationContextValue {
  startAnimation: boolean,
  setStartAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  animationCoord: {x: number, y: number};
  setAnimationCoord: React.Dispatch<React.SetStateAction<{x: number, y: number}>>;
}

export const AnimationContext = React.createContext<AnimationContextValue | undefined>(undefined);

export const useAnimationContext = () => {
    const animationContext = React.useContext(AnimationContext);
    if (animationContext === undefined) {
      return {
        startAnimation: false,
        setStartAnimation: () => {return},
        animationCoord: {x: 0, y: 0},
        setAnimationCoord: () => {return},
      }
    }
    return animationContext;
};