import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Fetches all activities from the pet.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The response data or an error message.
 */
const getAllActivityFromPet = async (accessToken: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/all`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
    }
}

/**
 * Fetches today's activities from the pet.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The response data or null in case of an error.
 */
const getTodayActiviyFromPet = async (accessToken: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/today`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
    return null;
  }
}

/**
 * Deletes a single activity.
 * @param {string} accessToken - The access token for authorization.
 * @param {string} id - The ID of the activity.
 * @param {number} index - The index of the activity.
 * @returns {Promise<void>}
 */
const deleteSingleActivity = async (accessToken: string, id: string, index: number) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}/${index}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

/**
 * Deletes an activity.
 * @param {string} accessToken - The access token for authorization.
 * @param {string} id - The ID of the activity.
 * @returns {Promise<void>}
 */
const deleteActivity = async (accessToken: string, id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

/**
 * Creates a new activity.
 * @param {string} accessToken - The access token for authorization.
 * @param {Object[]} activity - The activity data.
 * @param {string} date - The creation date.
 * @returns {Promise<any>} - The response data.
 */
const createActivity = async (accessToken: string, activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/activity `,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
      return response.data
  } catch (error: any) {
      throw error;
  }
}

/**
 * Updates today's activity.
 * @param {string} accessToken - The access token for authorization.
 * @param {Object[]} activity - The activity data.
 * @param {string} date - The update date.
 * @returns {Promise<any>} - The response data.
 */
const updateTodayActivity = async (accessToken: string, activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/activity/today`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
      return response.data
  } catch (error: any) {
      throw error;
  }
}

/**
 * Updates an activity.
 * @param {string} accessToken - The access token for authorization.
 * @param {Object} activity - The activity data.
 * @param {string} id - The ID of the activity.
 * @returns {Promise<void>}
 */
const updateActivity = async (accessToken: string, activity: {created_at: string, duration: string, type: string, id: number}, id: string) => {
  try {
    const data = JSON.stringify({activity, id}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/activity `,
      data,
      {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

export {
  getTodayActiviyFromPet,
  getAllActivityFromPet,
  createActivity,
  deleteActivity,
  deleteSingleActivity,
  updateTodayActivity,
  updateActivity
}