import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Image } from '../component/imageUploader/ImageUploader';

/**
 * Call the server to retrieve the behavior of the day.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The behavior of the day or an error message.
 * @deprecated use getLastBehavior instead.
 */
const getBehaviorToday = async (accessToken: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/behavior/today`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
    }
}

/**
 * Call the server to retrieve the last behavior registered.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The last behavior registered or an error message.
 */
const getLastBehavior = async (accessToken: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/behavior/last`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
    return [""];
  }
}

/**
 * Call the server to create a behavior record.
 * @param {string} accessToken - The access token for authorization.
 * @param {number[]} behaviors - Array of behavior IDs.
 * @param {string} other - Additional behavior information.
 * @param {Image} image - Image associated with the behavior.
 * @returns {Promise<any>} - The response data.
 */
const createBehavior = async (accessToken: string, behaviors: number[], other: string, image: Image) => {
  try {
      const data = JSON.stringify({behaviors, other, image}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/behavior`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
      return response.data;
  } catch (error: any) {
      throw error;
  }
}

/**
 * Call the server to modify the behavior of the day.
 * @param {string} accessToken - The access token for authorization.
 * @param {string[]} behaviors - Array of behavior IDs.
 * @param {string} other - Additional behavior information.
 * @returns {Promise<any>} - The response data.
 * @deprecated
 */
const modifyBehaviorToday = async (accessToken: string, behaviors: string[], other: string) => {
  try {
      const data = JSON.stringify({behaviors, other}, null, 1)
      const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/behavior/today `,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
      return response.data;
  } catch (error: any) {
      throw error;
  }
}

/**
 * Call the server to retrieve the behavior analysis.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The behavior analysis.
 */
const getBehaviorAnalysis = async (accessToken: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/behavior/analysis`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

export {
  getBehaviorToday,
  getLastBehavior,
  createBehavior,
  modifyBehaviorToday,
  getBehaviorAnalysis
}