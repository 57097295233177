import axios from 'axios';

/**
 * Fetches invitations.
 * @param accessToken - The access token for authorization.
 * @returns The invitation data or an empty array if an error occurs.
 */
const getInvitation = async (accessToken: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/invitation`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      return [];
    }
}

/**
 * Creates a new invitation.
 * @param accessToken - The access token for authorization.
 * @param familyId - The ID of the family.
 * @param email - The email to send the invitation to.
 * @returns The response data or an error.
 */
const createInvitation = async (accessToken: string, familyId: string, email: string) => {
    try {
        const lang = localStorage.getItem("savedLanguage");
        const data = JSON.stringify({familyId, email, lang: lang}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/invitation/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            }
        );
        return response.data;
    } catch (error: any) {
        return error;
    }
}

/**
 * Validates an invitation.
 * @param accessToken - The access token for authorization.
 * @param invitationId - The ID of the invitation.
 * @param accept - Whether to accept the invitation.
 */
const validateInvitation = async (accessToken: string, invitationId: string, accept: boolean) => {
    try {
        const data = JSON.stringify({invitationId, accept}, null, 1)
        await axios.post(
            `${process.env.REACT_APP_API_URL}/invitation/validate`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            }
        );
    } catch (error: any) {
        throw error;
    }
}

export {
    getInvitation,
    createInvitation,
    validateInvitation
}
