import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./HomePageFourthPart.css";

function HomePageFourthPart(props) {
    const {t} = useTranslation();

    return (
        <Flex className="home-page-fourth-part" align="center" justify="start" vertical>
            <Typography.Title style={props.titleStyle}>{t("home.fourth-part.title")}</Typography.Title>
            <Flex className="fourth-part-content" align="center" justify="space-between" gap={"large"} vertical={props.isSmallScreen}>
                <img src="/assets/images/landingPage/storyIllustration.png" alt="Story Illustration" className="fourth-part-illustration"/>
                <Flex align="center" justify="center" vertical gap={"large"}>
                    <Typography.Text className="fourth-part-paragraph">{t("home.fourth-part.first-paragraph")}</Typography.Text>
                    <Typography.Text className="fourth-part-paragraph">{t("home.fourth-part.second-paragraph")}</Typography.Text>
                    <Typography.Text className="fourth-part-paragraph">{t("home.fourth-part.third-paragraph")}</Typography.Text>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default HomePageFourthPart;