export const IdealWeightDog = [
    {
        value: 'Affenpinscher',
        male: [3, 6],
        female: [3, 6],
    },
    {
        value: 'Afghan Hound',
        male: [27, 34],
        female: [23, 27],
    },
    {
        value: 'Airedale Terrier',
        male: [23, 29],
        female: [18, 20],
    },
    {
        value: 'Akita Inu',
        male: [45, 59],
        female: [32, 45],
    },
    {
        value: 'Alaskan Malamute',
        male: [36, 43],
        female: [32, 38],
    },
    {
        value: 'American Akita',
        male: [45, 59],
        female: [32, 45],
    },
    {
        value: 'American Bulldog',
        male: [30, 58],
        female: [27, 45],
    },
    {
        value: 'American Cocker Spaniel',
        male: [11, 14],
        female: [9, 12],
    },
    {
        value: 'American Foxhound',
        male: [29, 34],
        female: [25, 30],
    },
    {
        value: 'American Staffordshire Terrier',
        male: [25, 30],
        female: [22, 27],
    },
    {
        value: 'American Water Spaniel',
        male: [13, 20],
        female: [11, 18],
    },
    {
        value: 'Anatolian Shepherd',
        male: [50, 65],
        female: [40, 55],
    },
    {
        value: 'Appenzell Cattle Dog',
        male: [22, 32],
        female: [18, 25],
    },
    {
        value: 'Ariege Pointing Dog',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Ariegeois',
        male: [28, 30],
        female: [25, 28],
    },
    {
        value: 'Artois Hound',
        male: [28, 30],
        female: [25, 28],
    },
    {
        value: 'Atlas Mountain Dog (Aidi)',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Australian Cattle Dog',
        male: [15, 22],
        female: [14, 20],
    },
    {
        value: 'Australian Kelpie',
        male: [14, 21],
        female: [14, 21],
    },
    {
        value: 'Australian Shepherd',
        male: [25, 29],
        female: [18, 25],
    },
    {
        value: 'Australian Silky Terrier',
        male: [3.5, 4.5],
        female: [3.5, 4.5],
    },
    {
        value: 'Australian Terrier',
        male: [6.5, 7.5],
        female: [6.5, 7.5],
    },
    {
        value: 'Austrian Black and Tan Hound',
        male: [15, 22],
        female: [15, 22],
    },
    {
        value: 'Austrian Pinscher',
        male: [12, 18],
        female: [12, 18],
    },
    {
        value: 'Auvergne Pointer',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Azawakh',
        male: [20, 25],
        female: [15, 20],
    },
    {
        value: 'Basenji',
        male: [10, 12],
        female: [9, 11],
    },
    {
        value: 'Basset Fauve de Bretagne',
        male: [16, 18],
        female: [14, 16],
    },
    {
        value: 'Basset Hound',
        male: [23, 29],
        female: [20, 27],
    },
    {
        value: 'Bavarian Mountain Scent Hound',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Beagle',
        male: [10, 11],
        female: [9, 10],
    },
    {
        value: 'Beagle Harrier',
        male: [18, 27],
        female: [18, 27],
    },
    {
        value: 'Bearded Collie',
        male: [18, 27],
        female: [18, 27],
    },
    {
        value: 'Bedlington Terrier',
        male: [8, 10],
        female: [8, 10],
    },
    {
        value: 'Belgian Shepherd',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Bergamasco Shepherd Dog',
        male: [32, 38],
        female: [26, 32],
    },
    {
        value: 'Bernese Mountain Dog',
        male: [38, 50],
        female: [36, 48],
    },
    {
        value: 'Bichon Frise',
        male: [5, 10],
        female: [5, 10],
    },
    {
        value: 'Billy',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Black and Tan Coonhound',
        male: [29, 34],
        female: [25, 30],
    },
    {
        value: 'Bloodhound',
        male: [40, 50],
        female: [36, 45],
    },
    {
        value: 'Blue Gascony',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Blue Picardy Spaniel',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Boerboel',
        male: [65, 90],
        female: [55, 70],
    },
    {
        value: 'Bohemian Wire-haired Pointing Griffon',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Bolognese',
        male: [2.5, 4],
        female: [2.5, 4],
    },
    {
        value: 'Border Collie',
        male: [14, 20],
        female: [12, 19],
    },
    {
        value: 'Border Terrier',
        male: [5.9, 7.1],
        female: [5.1, 6.4],
    },
    {
        value: 'Borzoï',
        male: [34, 47],
        female: [27, 41],
    },
    {
        value: 'Bosnian Broken-haired Hound - Called Barak',
        male: [16, 24],
        female: [16, 24],
    },
    {
        value: 'Boston Terrier',
        male: [6, 11],
        female: [4.5, 9],
    },
    {
        value: 'Bourbonnais Pointing Dog',
        male: [18, 25],
        female: [16, 22],
    },
    {
        value: 'Bouvier des Ardennes',
        male: [22, 35],
        female: [18, 30],
    },
    {
        value: 'Bouvier des Flandres',
        male: [36, 45],
        female: [27, 36],
    },
    {
        value: 'Boxer',
        male: [30, 32],
        female: [25, 27],
    },
    {
        value: 'Bracco Italiano (Italian Pointing dog)',
        male: [25, 40],
        female: [25, 40],
    },
    {
        value: 'Brazilian Terrier',
        male: [6, 10],
        female: [6, 10],
    },
    {
        value: 'Briard',
        male: [34, 45],
        female: [27, 36],
    },
    {
        value: 'Briquet Griffon Vendeen',
        male: [16, 20],
        female: [14, 18],
    },
    {
        value: 'Brittany',
        male: [14, 18],
        female: [13, 17],
    },
    {
        value: 'Broholmer',
        male: [50, 70],
        female: [40, 60],
    },
    {
        value: 'Bull Terrier',
        male: [23, 32],
        female: [18, 23],
    },
    {
        value: 'Bulldog',
        male: [24, 25],
        female: [22, 23],
    },
    {
        value: 'Bullmastiff',
        male: [50, 59],
        female: [41, 50],
    },
    {
        value: 'Burgos Pointing Dog',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Cairn Terrier',
        male: [6, 7.5],
        female: [6, 7.5],
    },
    {
        value: 'Canaan Dog',
        male: [18, 25],
        female: [16, 20],
    },
    {
        value: 'Canarian Warren Hound',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Cane Corso',
        male: [45, 50],
        female: [40, 45],
    },
    {
        value: 'Castro Laboreiro Dog',
        male: [40, 50],
        female: [35, 45],
    },
    {
        value: 'Catalan Sheepdog',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Caucasian Shepherd Dog',
        male: [50, 100],
        female: [45, 80],
    },
    {
        value: 'Cavalier King Charles Spaniel',
        male: [5, 9],
        female: [5, 8],
    },
    {
        value: 'Central Asia Shepherd Dog',
        male: [50, 79],
        female: [40, 65],
    },
    {
        value: 'Cesky Terrier',
        male: [6, 10],
        female: [6, 10],
    },
    {
        value: 'Chesapeake Bay Retriever',
        male: [29, 36],
        female: [25, 32],
    },
    {
        value: 'Chihuahua',
        male: [1.5, 3],
        female: [1.5, 3],
    },
    {
        value: 'Chinese Crested Dog',
        male: [2, 5],
        female: [2, 5],
    },
    {
        value: 'Chow Chow',
        male: [25, 32],
        female: [20, 27],
    },
    {
        value: 'Cirneco Dell’Etna',
        male: [8, 12],
        female: [8, 12],
    },
    {
        value: 'Clumber Spaniel',
        male: [29, 34],
        female: [25, 30],
    },
    {
        value: 'Coarse-haired Styrian Hound',
        male: [15, 18],
        female: [15, 18],
    },
    {
        value: 'Cocker Spaniel',
        male: [13, 16],
        female: [12, 15],
    },
    {
        value: 'Collie Smooth',
        male: [20, 30],
        female: [18, 25],
    },
    {
        value: 'Coton de Tulear',
        male: [4, 6],
        female: [3.5, 5],
    },
    {
        value: 'Croatian Shepherd Dog',
        male: [13, 20],
        female: [13, 20],
    },
    {
        value: 'Curly-Coated Retriever',
        male: [29, 36],
        female: [25, 32],
    },
    {
        value: 'Cursinu',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Czechoslovakian Wolfdog',
        male: [26, 30],
        female: [20, 25],
    },
    {
        value: 'Dachshund',
        male: [7, 14],
        female: [7, 14],
    },
    {
        value: 'Dalmatian',
        male: [27, 32],
        female: [24, 29],
    },
    {
        value: 'Dandie Dinmont Terrier',
        male: [8, 11],
        female: [8, 11],
    },
    {
        value: 'Deerhound',
        male: [38, 50],
        female: [34, 43],
    },
    {
        value: 'Deutsch Langhaar',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Dobermann',
        male: [34, 41],
        female: [29.5, 36],
    },
    {
        value: 'Dogue de Bordeaux',
        male: [54, 65],
        female: [45, 54],
    },
    {
        value: 'Drentsche Partridge Dog',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Drever',
        male: [14, 16],
        female: [14, 16],
    },
    {
        value: 'Dunker',
        male: [16, 22],
        female: [16, 22],
    },
    {
        value: 'Dutch Schapendoes',
        male: [12, 20],
        female: [12, 20],
    },
    {
        value: 'Dutch Shepherd',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Dutch Smoushond',
        male: [9, 10],
        female: [9, 10],
    },
    {
        value: 'East Siberian Laika',
        male: [18, 23],
        female: [18, 23],
    },
    {
        value: 'English Foxhound',
        male: [29, 34],
        female: [25, 30],
    },
    {
        value: 'English Pointer',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'English Setter',
        male: [29, 36],
        female: [25, 32],
    },
    {
        value: 'English Springer Spaniel',
        male: [20, 25],
        female: [18, 23],
    },
    {
        value: 'Entlebucher Mountain Dog',
        male: [20, 30],
        female: [18, 28],
    },
    {
        value: 'Estrela Mountain Dog',
        male: [40, 50],
        female: [30, 40],
    },
    {
        value: 'Eurasier',
        male: [23, 32],
        female: [18, 26],
    },
    {
        value: 'Field Spaniel',
        male: [18, 25],
        female: [16, 22],
    },
    {
        value: 'Finnish Lapphund',
        male: [15, 24],
        female: [15, 24],
    },
    {
        value: 'Finnish Spitz',
        male: [12, 15],
        female: [7, 10],
    },
    {
        value: 'Flat-Coated Retriever',
        male: [27, 36],
        female: [25, 32],
    },
    {
        value: 'French Bulldog',
        male: [9, 14],
        female: [8, 13],
    },
    {
        value: 'French Spaniel',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'German Longhaired Pointer',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'German Pinscher',
        male: [11, 20],
        female: [11, 20],
    },
    {
        value: 'German Shepherd',
        male: [30, 40],
        female: [22, 32],
    },
    {
        value: 'German Shorthaired Pointer',
        male: [25, 32],
        female: [20, 27],
    },
    {
        value: 'German Spaniel',
        male: [18, 25],
        female: [16, 22],
    },
    {
        value: 'German Wirehaired Pointer',
        male: [25, 32],
        female: [20, 27],
    },
    {
        value: 'Giant Schnauzer',
        male: [27, 48],
        female: [25, 34],
    },
    {
        value: 'Glen of Imaal Terrier',
        male: [15.5, 16],
        female: [15.5, 16],
    },
    {
        value: 'Golden Retriever',
        male: [29, 34],
        female: [25, 29],
    },
    {
        value: 'Gordon Setter',
        male: [25, 36],
        female: [20, 32],
    },
    {
        value: 'Grand Basset Griffon Vendeen',
        male: [18, 20],
        female: [16, 18],
    },
    {
        value: 'Great Dane',
        male: [54, 90],
        female: [45, 59],
    },
    {
        value: 'Great Swiss Mountain Dog',
        male: [50, 64],
        female: [40, 55],
    },
    {
        value: 'Greek Harehound',
        male: [17, 20],
        female: [17, 20],
    },
    {
        value: 'Greenland Dog',
        male: [30, 32],
        female: [25, 27],
    },
    {
        value: 'Greyhound',
        male: [27, 40],
        female: [25, 34],
    },
    {
        value: 'Griffon Bruxellois',
        male: [3, 5],
        female: [3, 5],
    },
    {
        value: 'Griffon Nivernais',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Hamilton Hound',
        male: [23, 27],
        female: [20, 23],
    },
    {
        value: 'Hanoverian Scenthound',
        male: [30, 40],
        female: [25, 35],
    },
    {
        value: 'Harrier',
        male: [18, 27],
        female: [18, 27],
    },
    {
        value: 'Havanese',
        male: [3, 6],
        female: [3, 6],
    },
    {
        value: 'Hovawart',
        male: [30, 40],
        female: [25, 35],
    },
    {
        value: 'Hungarian Greyhound',
        male: [27, 31],
        female: [22, 26],
    },
    {
        value: 'Hungarian Hound',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Hungarian Puli',
        male: [13, 15],
        female: [10, 13],
    },
    {
        value: 'Hungarian Pumi',
        male: [10, 15],
        female: [8, 13],
    },
    {
        value: 'Ibizan Hound',
        male: [20, 29],
        female: [20, 25],
    },
    {
        value: 'Icelandic Sheepdog',
        male: [12, 18],
        female: [10, 15],
    },
    {
        value: 'Irish Glen of Imaal Terrier',
        male: [15.5, 16],
        female: [15.5, 16],
    },
    {
        value: 'Irish Red and White Setter',
        male: [25, 32],
        female: [20, 27],
    },
    {
        value: 'Irish Setter',
        male: [29, 34],
        female: [25, 29],
    },
    {
        value: 'Irish Terrier',
        male: [11, 12],
        female: [11, 12],
    },
    {
        value: 'Irish Water Spaniel',
        male: [25, 30],
        female: [20, 26],
    },
    {
        value: 'Irish Wolfhound',
        male: [54, 70],
        female: [40, 55],
    },
    {
        value: 'Italian Greyhound',
        male: [3.6, 4.5],
        female: [3.6, 4.5],
    },
    {
        value: 'Italian Spinone',
        male: [32, 37],
        female: [28, 30],
    },
    {
        value: 'Jack Russell Terrier',
        male: [6, 8],
        female: [6, 8],
    },
    {
        value: 'Japanese Chin',
        male: [1.8, 3.2],
        female: [1.8, 3.2],
    },
    {
        value: 'Japanese Spitz',
        male: [5, 10],
        female: [5, 10],
    },
    {
        value: 'Kangal Shepherd Dog',
        male: [50, 66],
        female: [41, 54],
    },
    {
        value: 'Karelian Bear Dog',
        male: [25, 28],
        female: [17, 20],
    },
    {
        value: 'Karst Shepherd Dog',
        male: [30, 42],
        female: [25, 37],
    },
    {
        value: 'Keeshond',
        male: [16, 20],
        female: [16, 20],
    },
    {
        value: 'Kerry Blue Terrier',
        male: [15, 18],
        female: [15, 18],
    },
    {
        value: 'King Charles Spaniel',
        male: [3.6, 6.4],
        female: [3.6, 6.4],
    },
    {
        value: 'Kishu',
        male: [14, 23],
        female: [14, 23],
    },
    {
        value: 'Komondor',
        male: [50, 60],
        female: [40, 50],
    },
    {
        value: 'Kooikerhondje',
        male: [9, 11],
        female: [9, 11],
    },
    {
        value: 'Korean Jindo',
        male: [18, 23],
        female: [15, 19],
    },
    {
        value: 'Kuvasz',
        male: [45, 52],
        female: [32, 41],
    },
    {
        value: 'Labrador Retriever',
        male: [29, 36],
        female: [25, 32],
    },
    {
        value: 'Lagotto Romagnolo',
        male: [13, 16],
        female: [11, 14],
    },
    {
        value: 'Lakeland Terrier',
        male: [7, 8],
        female: [6.5, 7.5],
    },
    {
        value: 'Lancashire Heeler',
        male: [6, 7],
        female: [6, 7],
    },
    {
        value: 'Landseer',
        male: [50, 70],
        female: [45, 55],
    },
    {
        value: 'Lapponian Herder',
        male: [25, 32],
        female: [25, 32],
    },
    {
        value: 'Large Munsterlander',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Leonberger',
        male: [54, 77],
        female: [41, 59],
    },
    {
        value: 'Lhasa Apso',
        male: [6, 8],
        female: [5, 7],
    },
    {
        value: 'Löwchen',
        male: [4, 8],
        female: [4, 8],
    },
    {
        value: 'Maltese',
        male: [3, 4],
        female: [3, 4],
    },
    {
        value: 'Manchester Terrier',
        male: [5, 10],
        female: [5, 10],
    },
    {
        value: 'Maremma Sheepdog',
        male: [35, 45],
        female: [30, 40],
    },
    {
        value: 'Mastiff',
        male: [73, 100],
        female: [54, 77],
    },
    {
        value: 'Miniature Bull Terrier',
        male: [11, 15],
        female: [11, 15],
    },
    {
        value: 'Miniature Pinscher',
        male: [4, 6],
        female: [4, 6],
    },
    {
        value: 'Miniature Schnauzer',
        male: [5, 9],
        female: [5, 9],
    },
    {
        value: 'Neapolitan Mastiff',
        male: [60, 70],
        female: [50, 60],
    },
    {
        value: 'Newfoundland',
        male: [60, 70],
        female: [45, 55],
    },
    {
        value: 'Norfolk Terrier',
        male: [5, 5.5],
        female: [5, 5.5],
    },
    {
        value: 'Norwegian Buhund',
        male: [14, 18],
        female: [12, 16],
    },
    {
        value: 'Norwegian Elkhound',
        male: [23, 27],
        female: [20, 25],
    },
    {
        value: 'Norwegian Lundehund',
        male: [6, 7],
        female: [6, 7],
    },
    {
        value: 'Norwich Terrier',
        male: [5, 5.5],
        female: [5, 5.5],
    },
    {
        value: 'Nova Scotia Duck Tolling Retriever',
        male: [20, 23],
        female: [17, 20],
    },
    {
        value: 'Old English Sheepdog',
        male: [30, 45],
        female: [27, 35],
    },
    {
        value: 'Otterhound',
        male: [36, 54],
        female: [29, 41],
    },
    {
        value: 'Papillon',
        male: [3.6, 4.5],
        female: [3.6, 4.5],
    },
    {
        value: 'Parson Russell Terrier',
        male: [6, 8],
        female: [6, 8],
    },
    {
        value: 'Pekingese',
        male: [3.2, 6.4],
        female: [3.2, 6.4],
    },
    {
        value: 'Pembroke Welsh Corgi',
        male: [10, 14],
        female: [10, 14],
    },
    {
        value: 'Perro de Presa Canario',
        male: [50, 59],
        female: [38, 50],
    },
    {
        value: 'Peruvian Hairless Dog',
        male: [4, 25],
        female: [4, 25],
    },
    {
        value: 'Petit Basset Griffon Vendeen',
        male: [14, 15],
        female: [14, 15],
    },
    {
        value: 'Pharaoh Hound',
        male: [18, 27],
        female: [18, 27],
    },
    {
        value: 'Picardy Spaniel',
        male: [20, 25],
        female: [18, 22],
    },
    {
        value: 'Plott Hound',
        male: [20, 27],
        female: [18, 25],
    },
    {
        value: 'Podenco Canario',
        male: [20, 25],
        female: [16, 22],
    },
    {
        value: 'Pointer',
        male: [25, 34],
        female: [20, 29],
    },
    {
        value: 'Polish Greyhound',
        male: [27, 31],
        female: [22, 26],
    },
    {
        value: 'Polish Hound',
        male: [20, 32],
        female: [16, 26],
    },
    {
        value: 'Polish Lowland Sheepdog',
        male: [14, 23],
        female: [14, 23],
    },
    {
        value: 'Pomeranian',
        male: [1.9, 3.5],
        female: [1.9, 3.5],
    },
    {
        value: 'Poodle',
        male: [20, 32],
        female: [20, 32],
    },
    {
        value: 'Porcelaine',
        male: [25, 28],
        female: [25, 28],
    },
    {
        value: 'Portuguese Podengo',
        male: [16, 20],
        female: [16, 20],
    },
    {
        value: 'Portuguese Pointer',
        male: [20, 27],
        female: [16, 22],
    },
    {
        value: 'Portuguese Sheepdog',
        male: [17, 27],
        female: [17, 27],
    },
    {
        value: 'Portuguese Water Dog',
        male: [19, 27],
        female: [16, 23],
    },
    {
        value: 'Pug',
        male: [6, 8],
        female: [6, 8],
    },
    {
        value: 'Puli',
        male: [13, 15],
        female: [10, 13],
    },
    {
        value: 'Pumi',
        male: [10, 15],
        female: [8, 13],
    },
    {
        value: 'Pyrenean Mastiff',
        male: [70, 81],
        female: [60, 75],
    },
    {
        value: 'Pyrenean Mountain Dog',
        male: [50, 54],
        female: [36, 41],
    },
    {
        value: 'Pyrenean Sheepdog',
        male: [8, 15],
        female: [8, 15],
    },
    {
        value: 'Rafeiro do Alentejo',
        male: [45, 60],
        female: [35, 50],
    },
    {
        value: 'Rhodesian Ridgeback',
        male: [36, 41],
        female: [29, 34],
    },
    {
        value: 'Rottweiler',
        male: [50, 60],
        female: [35, 48],
    },
    {
        value: 'Rough Collie',
        male: [20, 30],
        female: [18, 25],
    },
    {
        value: 'Russian Black Terrier',
        male: [50, 60],
        female: [45, 50],
    },
    {
        value: 'Russian Toy',
        male: [1, 3],
        female: [1, 3],
    },
    {
        value: 'Saarloos Wolfdog',
        male: [36, 41],
        female: [30, 35],
    },
    {
        value: 'Saint Bernard',
        male: [64, 120],
        female: [54, 100],
    },
    {
        value: 'Saluki',
        male: [20, 30],
        female: [16, 25],
    },
    {
        value: 'Samoyed',
        male: [20, 30],
        female: [16, 20],
    },
    {
        value: 'Schapendoes',
        male: [12, 20],
        female: [12, 20],
    },
    {
        value: 'Schiller Hound',
        male: [18, 25],
        female: [18, 25],
    },
    {
        value: 'Schipperke',
        male: [3, 9],
        female: [3, 9],
    },
    {
        value: 'Scottish Deerhound',
        male: [39, 50],
        female: [34, 43],
    },
    {
        value: 'Scottish Terrier',
        male: [8.5, 10],
        female: [8.5, 10],
    },
    {
        value: 'Sealyham Terrier',
        male: [8, 9],
        female: [8, 9],
    },
    {
        value: 'Segugio Italiano',
        male: [18, 28],
        female: [16, 26],
    },
    {
        value: 'Shar Pei',
        male: [25, 29],
        female: [18, 25],
    },
    {
        value: 'Shetland Sheepdog',
        male: [6, 12],
        female: [6, 12],
    },
    {
        value: 'Shiba Inu',
        male: [8, 11],
        female: [6.8, 9],
    },
    {
        value: 'Shih Tzu',
        male: [4, 7.2],
        female: [4, 7.2],
    },
    {
        value: 'Siberian Husky',
        male: [20, 27],
        female: [16, 23],
    },
    {
        value: 'Silky Terrier',
        male: [3.5, 4.5],
        female: [3.5, 4.5],
    },
    {
        value: 'Skye Terrier',
        male: [16, 18],
        female: [16, 18],
    },
    {
        value: 'Sloughi',
        male: [20, 28],
        female: [16, 22],
    },
    {
        value: 'Small Munsterlander',
        male: [18, 27],
        female: [18, 27],
    },
    {
        value: 'Smooth Collie',
        male: [20, 30],
        female: [18, 25],
    },
    {
        value: 'Soft Coated Wheaten Terrier',
        male: [15.5, 18],
        female: [13.5, 16],
    },
    {
        value: 'Spanish Greyhound',
        male: [25, 30],
        female: [20, 25],
    },
    {
        value: 'Spanish Mastiff',
        male: [52, 100],
        female: [52, 100],
    },
    {
        value: 'Spanish Water Dog',
        male: [14, 22],
        female: [14, 22],
    },
    {
        value: 'Spinone Italiano',
        male: [32, 37],
        female: [28, 30],
    },
    {
        value: 'Stabyhoun',
        male: [15, 20],
        female: [14, 20],
    },
    {
        value: 'Staffordshire Bull Terrier',
        male: [13, 17],
        female: [11, 15],
    },
    {
        value: 'Standard Schnauzer',
        male: [16, 22],
        female: [14, 20],
    },
    {
        value: 'Sussex Spaniel',
        male: [23, 25],
        female: [23, 25],
    },
    {
        value: 'Swedish Lapphund',
        male: [19, 21],
        female: [19, 21],
    },
    {
        value: 'Swedish Vallhund',
        male: [9, 14],
        female: [9, 14],
    },
    {
        value: 'Swiss Hound',
        male: [15, 20],
        female: [15, 20],
    },
    {
        value: 'Thai Ridgeback',
        male: [16, 25],
        female: [16, 25],
    },
    {
        value: 'Tibetan Mastiff',
        male: [45, 73],
        female: [34, 54],
    },
    {
        value: 'Tibetan Spaniel',
        male: [4, 7],
        female: [4, 7],
    },
    {
        value: 'Tibetan Terrier',
        male: [8, 14],
        female: [8, 14],
    },
    {
        value: 'Tosa Inu',
        male: [36, 61],
        female: [36, 61],
    },
    {
        value: 'Toy Poodle',
        male: [3, 4],
        female: [3, 4],
    },
    {
        value: 'Transylvanian Hound',
        male: [25, 27],
        female: [22, 24],
    },
    {
        value: 'Treeing Walker Coonhound',
        male: [20, 36],
        female: [20, 36],
    },
    {
        value: 'Vizsla',
        male: [20, 30],
        female: [18, 25],
    },
    {
        value: 'Volpino Italiano',
        male: [4, 5],
        female: [4, 5],
    },
    {
        value: 'Weimaraner',
        male: [30, 40],
        female: [25, 35],
    },
    {
        value: 'Welsh Springer Spaniel',
        male: [16, 20],
        female: [16, 20],
    },
    {
        value: 'Welsh Terrier',
        male: [9, 10],
        female: [9, 10],
    },
    {
        value: 'West Highland White Terrier',
        male: [7, 10],
        female: [6, 7],
    },
    {
        value: 'Whippet',
        male: [12.5, 13.5],
        female: [11.5, 12.5],
    },
    {
        value: 'White Swiss Shepherd Dog',
        male: [30, 40],
        female: [25, 35],
    },
    {
        value: 'Wire Fox Terrier',
        male: [7.3, 8.6],
        female: [6.8, 8.2],
    },
    {
        value: 'Wirehaired Pointing Griffon',
        male: [20, 30],
        female: [20, 30],
    },
    {
        value: 'Wirehaired Vizsla',
        male: [20, 30],
        female: [18, 25],
    },
    {
        value: 'Xoloitzcuintli',
        male: [4, 20],
        female: [4, 20],
    },
    {
        value: 'Yorkshire Terrier',
        male: [2, 3.2],
        female: [2, 3.2],
    },
    {
        value: 'Yugoslavian Shepherd Dog',
        male: [35, 45],
        female: [30, 40],
    },
    {
        value: 'Zwergschnauzer',
        male: [4, 8],
        female: [4, 8],
    },
    {
        value: 'Zwergspitz',
        male: [1.8, 2.5],
        female: [1.8, 2.5],
    },
]

export const IdealWeightCat = [
    {
        value: 'Abyssin',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'American Curl',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'American Shorthair',
        male: [5, 7],
        female: [3, 5],
    },
    {
        value: 'American Wirehair',
        male: [5, 7],
        female: [3, 5],
    },
    {
        value: 'Anatoli',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Angora Turc',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'Asian',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Balinais',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'Bengal',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Birman',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Bleu Russe',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'Bobtail Américain',
        male: [5, 7],
        female: [3, 5],
    },
    {
        value: 'Bobtail Japonais',
        male: [3, 5],
        female: [2, 4],
    },
    {
        value: 'Bombay',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'British Longhair',
        male: [5, 8],
        female: [4, 6],
    },
    {
        value: 'British Shorthair',
        male: [5, 8],
        female: [4, 6],
    },
    {
        value: 'Burmese',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Burmilla',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Californian Spangled',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Chat de Goutière / Alley Cat',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Chartreux',
        male: [6, 7],
        female: [4, 5],
    },
    {
        value: 'Chausie',
        male: [6, 9],
        female: [5, 7],
    },
    {
        value: 'Cornish Rex',
        male: [3, 5],
        female: [2, 4],
    },
    {
        value: 'Cymric',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Devon Rex',
        male: [3, 5],
        female: [2, 4],
    },
    {
        value: 'Donskoy',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Dragon Li',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Egyptian Mau',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'European',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'European Shorthair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Exotic Shorthair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'German Rex',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Havana Brown',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Highland Fold',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Highland Lynx',
        male: [5, 9],
        female: [4, 7],
    },
    {
        value: 'Himalayan',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Javanais',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'Korat',
        male: [4, 5],
        female: [3, 4],
    },
    {
        value: 'Kurilian Bobtail',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'LaPerm',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Maine Coon',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Manx',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Munchkin',
        male: [3, 4],
        female: [2, 3],
    },
    {
        value: 'Nebelung',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Norvégien',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Ocicat',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Oriental Shorthair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Persan',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Peterbald',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Pixie Bob',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Ragdoll',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Ragamuffin',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Sacré de Birmanie',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Savannah',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Scottish Fold',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Selkirk Rex',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Serengeti',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Siamois',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Sibérien',
        male: [6, 9],
        female: [4, 6],
    },
    {
        value: 'Singapura',
        male: [2, 3],
        female: [1, 2],
    },
    {
        value: 'Snowshoe',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Sokoke',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Somali',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Sphynx',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Thaï',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Tiffany',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Tonkinois',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Toyger',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Turc de Van',
        male: [5, 7],
        female: [4, 6],
    },
    {
        value: 'Ural Rex',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'York Chocolat',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Aegean',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Australian Mist',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Chantilly-Tiffany',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Ceylan',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Colorpoint Shorthair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Cyprus',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Dwelf',
        male: [2, 3],
        female: [1, 2],
    },
    {
        value: 'Foldex',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'German Longhair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Havana',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Khao Manee',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Kohana',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Lykoi',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Mekong Bobtail',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Minuet',
        male: [3, 4],
        female: [2, 3],
    },
    {
        value: 'Napoleon',
        male: [3, 4],
        female: [2, 3],
    },
    {
        value: 'Ojos Azules',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Oriental Longhair',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Peke-Faced',
        male: [4, 6],
        female: [3, 5],
    },
    {
        value: 'Suphalak',
        male: [4, 6],
        female: [3, 5],
    },
]
