import { Image, message } from "antd";
import _ from 'lodash';

import { CAT_ICON, DOG_ICON } from "../../config/define";
import i18next from "i18next";

/**
 * @function savePetAvatars
 *
 * @description This function store the avatar in the local storage if there is nothing in it
 * or if it needs to be renew
 *
 * @param {{petId: string, url: string}[]} avatars An array of object containing pet associated to their avatar
 * @param {boolean} renew If the avatars contained in the local storage need to be renew
 */
export async function savePetAvatars(
    avatars: {petId: string, url: string}[],
    renew: boolean
) {
    if (localStorage.getItem("petsAvatar") && renew == false) {
        return;
    }
    if (renew) {
        localStorage.removeItem("petsAvatar")
    }
    const updatedAvatars: {[key: string]: string} = {};

    if (!avatars || avatars.length === 0) {
        return;
    }
    for (const avatar of avatars) {
        const res = await fetch(avatar.url);

        if (res.status === 404 && res.statusText === "Not Found") {
            Object.assign(updatedAvatars, {[avatar.petId]: ""});
            continue;
        }
        const blob = await res.blob();
        const reader = new FileReader();
        const dataUrl = await new Promise<string>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
        Object.assign(updatedAvatars, {[avatar.petId]: dataUrl});
    }
    try {
        localStorage.setItem("petsAvatar", JSON.stringify(updatedAvatars));
    } catch (e) {
        message.error(i18next.t("error.storage-error"));
        const emptyAvatars: {[key: string]: string} = {};
        for (const avatar of avatars)
            Object.assign(emptyAvatars, {[avatar.petId]: ""});
        localStorage.setItem("petsAvatar", JSON.stringify(emptyAvatars));
    }
}

/**
 * @function assignPetAvatar
 *
 * @description This function searches for the pet avatars in the localstorage and
 * assign them to each pet in the obj object
 *
 * @param {any} obj An object where to store all the pet avatar
 * @param {string} petIdPath The path where to find the id of the pet
 * @param {string} avatarStoragePath The path where to store the avatar of the pet
 * @param {boolean} isAnArray Wether the obj object is an array or not
 * @returns {any} The obj object with the avatar variable set
 */
export async function assignPetAvatar(
    obj: any,
    petIdPath: string,
    avatarStoragePath: string ,
    isAnArray: boolean
) {
    if (localStorage.getItem("petsAvatar") == null || !obj) {
        return obj;
    }
    const avatars = JSON.parse(localStorage.getItem("petsAvatar") as string);

    if (isAnArray) {
        obj.forEach((singleObj: any) => {
            _.set(singleObj, avatarStoragePath, avatars[_.get(singleObj, petIdPath)])
        });
    } else {
        _.set(obj, avatarStoragePath, avatars[_.get(obj, petIdPath)])
    }
    return obj;
}

interface AvatarSettings {
    species: string,
    iconSize: {width: string, height: string},
    imageSize?: {width: string, height: string}
    url: string,
}

/**
 * @function PetAvatar
 *
 * @description Render an avatar for a pet based on the given parameter. If an url is provided,
 * an image will be displayed, if not default icone will be, based on the species of the pet
 *
 * @param {string} species The species of the pet (e.g., 'dog', 'cat').
 * @param {string} url The URL of the pet's custom image. If empty, the default icon is used.
 * @param {{string, string}} iconSize The width and the height of the icon {width, height}
 * @param {{string, string}} imageSize The width and the height of the image {width, height}
 * @returns {JSX.Element} The pet avatar
 */
function PetAvatar(settings: AvatarSettings) {
    return (
        <>
            {!settings.url || typeof(settings.url) != "string"
                ?   <>
                        {settings.species == 'dog'
                            ? DOG_ICON(settings.iconSize.width, settings.iconSize.height)
                            : CAT_ICON(settings.iconSize.width, settings.iconSize.height)}
                    </>
                :   <Image
                        style={{ borderRadius: '150px' }}
                        width={settings?.imageSize?.width || settings.iconSize.width}
                        src={settings.url}
                        alt="avatar"
                        preview={false}
                    />
            }
        </>
    );
}

export default PetAvatar;