import axios from "axios";
import { ImageUploader } from "../component/imageUploader/ImageUploader";

/**
 * Creates an image from a URL.
 * @param presignedUrl - The presigned URL for the image.
 * @param mimeType - The MIME type of the image.
 * @param file - The image file.
 * @returns The response from the server.
 */
const createImageFromUrl = async (presignedUrl: string, mimeType: string, file: any) => {
    try {
      const response = await axios.put(presignedUrl, file,
        {
          headers: {
            'Content-Type': mimeType
          }
        }
      );
      return response;
    } catch (err: any) {
      throw err;
    }
}

export {
    createImageFromUrl,
}