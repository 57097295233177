import { Button, Card, Divider, Flex, message, Popconfirm, Typography } from "antd";
import { useEffect, useState } from "react";
import familyRequest from "../../request/family";
import AddingModal from "../profile/modals/AddingModal";
import { useGlobalState } from "../../state/globalState";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import SingleFamilyDisplay from "./SingleFamilyDisplay";
import FamilyInvitation from "./FamilyInvitation";
import { Invitation } from "../profile/ProfileDisplay";

interface Member {
    firstname: string,
    lastname: string,
    email: string,
    id: string
}

interface Pet {
    name: string,
    species: string,
    breed: string,
    id: string,
    avatar: string,
    type: string
}

interface Family {
    members?: Member[],
    pets?: Pet[],
    owner?: string,
    name?: string
    id: string
}

function FamilyDisplay ({user, invitations}: {user: any, invitations: Invitation[]}) {
    const [families, setFamilies] = useState<Family[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [newFamilyModalOpen, setNewFamilyModalOpen] = useState<boolean>(false);
    const [userPets, setUserPets] = useGlobalState('userPets');
    const {t} = useTranslation();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [reRender, setReRender] = useGlobalState('reRender');

    const getAccessToken = async () => {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                    scope: "openid profile email",
                },
            });
            return accessToken;
        }
        return "";
    }

    async function handleNewFamily(name: string) {
        const response = await familyRequest.createFamily(await getAccessToken(), name);
        if (response.status != 201)
            return message.error(t("family.requests.new-error"));
        message.success(t("family.requests.new-success"));
        setNewFamilyModalOpen(false);
        getFamilies();
    }

    function getPetAvatar(pet_id: string) {
        const pet = userPets.find((pet: any) => pet._id === pet_id);
        return pet?.avatar;
    }

    function getType(pet_id: string) {
        const pet = userPets.find((pet: any) => pet._id === pet_id);
        return pet?.type;
    }

    async function getFamilies() {
        setLoading(true);
        const results = await familyRequest.getFamilies(await getAccessToken());
        if (!results || results.length === 0)
            return setLoading(false);
        const families = [];
        for (const result of results) {
            const tempFamily = {
                members: result.members.map((member: any) => ({
                    firstname: member.firstname,
                    lastname: member.lastname,
                    email: member.email,
                    id: member._id
                })),
                pets: result.pets.map((pet: any) => ({
                    name: pet.name,
                    species: pet.species,
                    breed: pet.breed,
                    id: pet._id,
                    avatar: getPetAvatar(pet._id),
                    type: getType(pet._id)
                })),
                owner: result.owner,
                name: result.name,
                id: result._id
            }
            families.push(tempFamily);
        }
        setFamilies(families);
        setLoading(false);
    }

    useEffect(() => {
        getFamilies();
    }, [reRender]);

    return (
        <Flex align="center" justify="center" style={{ width: '100%' }} vertical>
            {invitations.length >= 1 &&
                <FamilyInvitation invitations={invitations}/>
            }
            {loading ? (
                <Typography.Text>{t("family.loading")}</Typography.Text>
            ) : (
                <div style={{ maxWidth: '800px', width: '100%' }}>
                    {families.map((family, key) => (
                        <SingleFamilyDisplay key={key} family={family} user={user} getFamilies={getFamilies} />
                    ))}
                </div>
            )}
            {families.length > 0 && <Divider />}
            <Button
                type="primary"
                onClick={() => setNewFamilyModalOpen(true)}
                style={{marginBottom: "20px"}}
            >
                {t("family.create-button")}
            </Button>
            <AddingModal
                title={t("family.create-button")}
                open={newFamilyModalOpen}
                onCancel={() => setNewFamilyModalOpen(false)}
                onOk={handleNewFamily}
                description={t("family.create-description")}
                placeholder={t("form.name")}
            />
        </Flex>
    )
}

export default FamilyDisplay;