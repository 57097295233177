import axios from 'axios';

/**
 * Creates selected pet cookies.
 * @param accessToken - The access token for authorization.
 * @param id - The ID of the pet.
 * @returns The response data or an empty array if an error occurs.
 */
const createSelectedPetCookies = async (accessToken: string, id: string) => {
    try {
        const data = JSON.stringify({id: id}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/pet`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
        return [""];
    }
}

/**
 * Fetches the user's selected pet.
 * @param accessToken - The access token for authorization.
 * @returns The response data or an error response if an error occurs.
 */
const getUserSelectedPet = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/pet`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return error.response;
    }
}

/**
 * Fetches the user's selected pet avatar.
 * @param accessToken - The access token for authorization.
 * @returns The response data or an empty string if an error occurs.
 */
const getUserSelectedPetAvatar = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/pet/avatar`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return '';
    }
}

export {
    createSelectedPetCookies,
    getUserSelectedPet,
    getUserSelectedPetAvatar
}
