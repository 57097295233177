import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { motion, AnimatePresence} from "framer-motion";
import { ReactComponent as Score } from "../../assets/icons/score/points.svg";
import { useAnimationContext } from "../../contexts/Animation";

import dayjs from 'dayjs'

interface AnimationProps {
  scoreCoord: {x: number, y: number},
}

function getRandomCoordinates(x0: number, y0: number, minRadius: number, maxRadius: number) {
  const angle = Math.random() * 2 * Math.PI;
  const radius = Math.random() * (maxRadius - minRadius) + minRadius;

  const x = x0 + radius * Math.cos(angle);
  const y = y0 + radius * Math.sin(angle);

  return { x, y };
}

function Animation(props: PropsWithChildren<AnimationProps>) {
  const {setStartAnimation, animationCoord} = useAnimationContext()

  const points = Array.from({length: 8}, () => getRandomCoordinates(animationCoord.x, animationCoord.y, 45, 45))

  const animationEnding: number[] = [];

  const handleComplete = (i: number) => {
    animationEnding.push(i);
    if (animationEnding.length == 8) {
      setStartAnimation(false)
      animationEnding.length = 0
    }
  }

  console.log("starting with : ", props.scoreCoord)
  return (
    <div className="animation-container" style={{ width: '100vw', height:'100vh', position: "absolute", top: 0, left: 0 }}>
      <AnimatePresence>
        {points.map((_, i) => (
          <motion.div
            key={i}
            className="box"
            style={{ height: '20px', width: '20px', position: 'absolute', display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1 }}
            initial={{
              x: _.x,
              y: _.y,
            }}
            animate={{
              x: props.scoreCoord.x,
              y: props.scoreCoord.y,
              transitionEnd: {
                display: "none",
              }
            }}
            transition={{
              delay: (Math.random() * 50) * 0.01 + 0.5,
              duration: 1.5,
              ease: "easeOut",
            }}
            onAnimationComplete={() => handleComplete(i)}
          >
            <Score/>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export default Animation;