import { createGlobalState } from 'react-hooks-global-state'

const { useGlobalState }: any = createGlobalState(
    {
        isLogin: false,
        isLoading: true,

        userPets: [""],
        selectedPetName: "",
        numberOfPets: 0,

        reRender: "",
        reRenderSelectPet: "",
        reRenderUserScore: "",

        footerVisibility: false,
        invitations: []
    }
);

export { useGlobalState };