import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

type Treatment = {
  pet: { pet_id: string },
  name: string,
  date: string,
  duration: {number: number, unit: string},
  every: {number: number, unit: string},
  treatment: string,
  notes: string
}

/**
 * Function to create event objects from the date.medicines_date field
 * in the medicine object
 *
 * @param events array of object with the events
 * @returns the new array with the medicines added
 */
const formatMedicines = (events: any) => {
  const newEvents: any[] = [];

  events.forEach((event: any, i: number) => {
    if (event.name == "medicines") {
      event.date.medicines_date.forEach((date: string, j: number) => {
        const eventToPush = {
          _id: event._id,
          index: j,
          type: "T",
          pet: event.pet,
          name: event.name,
          date: date,
          duration: event.duration,
          frequency: event.frequency,
          document_date: event.date,
          treatment: event.treatment,
          notes: event.notes,
          to_link: false,
          to_renew: false,
          user_id: event.user_id,
          vet: event.vet
        }
        newEvents.push(eventToPush);
      });
    } else {
      newEvents.push(event)
    }
  });
  return newEvents;
}

/**
 * Function to get events from pets
 *
 * @param accessToken the access token
 * @param pets the pets
 * @returns the events from pets
 */
async function getEventsFromPets(accessToken: string, pets: any) {
  try {
    const query = pets.join(",");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/event/pets/?pets=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return formatMedicines(response.data)
  } catch(error: any) {
    console.log(`there was an error: ${error}`);
    return [""];
  }
}

/**
 * Function to get events from user
 *
 * @param accessToken the access token
 * @returns the events from user
 */
const getEventsFromUser = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/event/all`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
            }
        );
        return response.data;
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
        return [""];
    }
}

/**
 * Function to get events from selected pet
 *
 * @param accessToken the access token
 * @returns the events from selected pet
 */
const getEventsFromSelectedPet = async (accessToken: string) => {
  try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/event/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
          }
      );
      return formatMedicines(response.data);
  } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
  }
}

/**
 * Function to create a treatment
 *
 * @param accessToken the access token
 * @param param the treatment parameters
 */
const createTreatment = async (accessToken: string, param: Treatment) => {
  try {
    const {pet, name, date, duration, every, treatment, notes} = param
      const data = JSON.stringify({pet, name, date, duration, every, treatment, notes}, null, 1)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/event/treatment`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to create an appointment
 *
 * @param accessToken the access token
 * @param pet the pet
 * @param name the name
 * @param date the date
 * @param vet the vet
 * @param motive the motive
 * @param notes the notes
 */
const createAppointment = async (accessToken: string, pet: { pet_id: string }, name: string, date: string, vet: { vet_id: string }, motive: string, notes: string) => {
  try {
    const data = JSON.stringify({pet, name, date, vet, motive, notes}, null, 1)
    const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/event/appointment`,
      data,
      {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to get notes
 *
 * @param accessToken the access token
 * @param eventId the event id
 * @returns the notes
 */
const getNotes = async (accessToken: string, eventId: string) => {
  try {
    console.log('je suis dans les requête notes')
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/event/notes/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
          },
      );
    return response;
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to update an event
 *
 * @param accessToken the access token
 * @param pet the pet
 * @param date the date
 * @param vet the vet
 * @param treatment the treatment
 * @param motive the motive
 * @param notes the notes
 * @param eventId the event id
 */
const updateEvent = async (accessToken: string, pet: { pet_id: string }, date: string, vet: {vet_id: string}, treatment: string, motive: string, notes: string, eventId: string) => {
  try {
    const data = JSON.stringify({pet, date, vet, treatment, motive, notes, eventId}, null, 1)
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/event/`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Update a medicine event
 * @param accessToken the access token
 * @param param the param to update the medicine
 */
const updateMedicines = async (accessToken: string, param: any) => {
  try {
    const {id, index, pet, date, medicines_date, frequency, renew_date, treatment, notes} = param;
    const data = JSON.stringify({id, index, pet, date, medicines_date, frequency, renew_date, treatment, notes}, null, 1)
    await axios.put(
      `${process.env.REACT_APP_API_URL}/event/medicines`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      },
    );
  } catch (error: any) {
    throw error;
  }
}

/**
 * remove a medicine from the array
 * @param accessToken ther access token
 * @param param the param to indicate which medicine needs to be removed
 */
const removeMedicinesFromArray = async (accessToken: string, param: any) => {
  try {
    const {id, index, date} = param;
    const data = JSON.stringify({id, index, date}, null, 1)
    await axios.put(
      `${process.env.REACT_APP_API_URL}/event/remove/medicines`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      },
    );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to remove a link
 *
 * @param accessToken the access token
 * @param treatmentId the treatment id
 * @param appointmentId the appointment id
 */
const removeLink = async (accessToken: string, treatmentId: string, appointmentId: string) => {
  try {
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/event/treatment/link/${treatmentId}/${appointmentId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        },
      );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to validate a treatment
 *
 * @param accessToken the access token
 * @param eventId the event id
 */
const validateTreatment = async (accessToken: string, eventId: string) => {
  try {
    const data = JSON.stringify({eventId}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/event/validate`,
        data,
        {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
    );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to link a treatment
 *
 * @param accessToken the access token
 * @param treatmentId the treatment id
 * @param appointmentId the appointment id
 */
const linkTreatment = async (accessToken: string, treatmentId: string, appointmentId: string) => {
  try {
    const data = JSON.stringify({treatmentId, appointmentId}, null, 1)
    await axios.put(
      `${process.env.REACT_APP_API_URL}/event/treatment/link`,
        data,
        {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
    );
  } catch (error: any) {
    throw error;
  }
}

/**
 * Function to delete an event
 *
 * @param accessToken the access token
 * @param id the event id
 */
const deleteEvent = async (accessToken: string, id: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/event/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
    }
}

export {
  getEventsFromUser,
  getEventsFromPets,
  getEventsFromSelectedPet,
  deleteEvent,
  createTreatment,
  createAppointment,
  removeLink,
  updateEvent,
  updateMedicines,
  removeMedicinesFromArray,
  linkTreatment,
  validateTreatment
}