import { Flex } from "antd";
import { Link } from "react-router-dom";
import ChangeLang from "../lang/ChangeLang";

import './HomePageHeader.css';
import AccessAppButton from "./AccessAppButton/AccessAppButton";

function HomePageHeader(props: any) {

    return (
        <Flex align="center" justify="space-around" className="home-header">
            <Link to='/'>
                <img
                    className='header-logo'
                    src="assets/images/landingPage/AppLogoBlue.png"
                    width={115}
                    alt="App logo"
                />
            </Link>
            {!props.isSmallScreen && <AccessAppButton small/>}
            <ChangeLang dropDownMode/>
        </Flex>
    );
}

export default HomePageHeader;