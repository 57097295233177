import { Flex, Typography } from "antd";

import "./HomePageSeventhPart.css";
import { useTranslation } from "react-i18next";
import AccessAppButton from "../AccessAppButton/AccessAppButton";

function HomePageSeventhPart(props) {
    const {t} = useTranslation();

    return (
        <Flex className="home-page-seventh-part" align="center" justify="start">
            {!props.isSmallScreen && <img src="/assets/images/landingPage/mission/img_4.jpg" alt="Seventh Part Illustration" className="seventh-part-illustration"/>}
            <Flex vertical justify="space-evenly" gap={props.isSmallScreen ? 35 : 70} className="seventh-part-content" align={props.isSmallScreen ? "center" : "start"}>
                <img src="/assets/images/landingPage/AppLogo.png" alt="App Logo" style={{width: (props.isSmallScreen ? "40%" : "25%")}}/>
                <Typography.Title style={props.titleStyle}>{t("home.seventh-part.title")}</Typography.Title>
                <Typography.Text className="seventh-part-email">p8tcare@gmail.com</Typography.Text>
                <AccessAppButton white small={props.isSmallScreen}/>
            </Flex>
        </Flex>
    );
}

export default HomePageSeventhPart;