import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./HomePageFifthPart.css";

function HomePageFifthPart(props) {
    const {t} = useTranslation();

    return (
        <Flex vertical align="center" justify="start" className="home-page-fifth-part" gap={"large"}>
            <Typography.Title style={props.titleStyle}>{t("home.fifth-part.title")}</Typography.Title>
            <Flex align="center" justify="center" className="fifth-part-content" gap={"large"} vertical={props.isSmallScreen}>
                <img src="/assets/images/landingPage/joinUs/img_1.jpg" alt="Illustration 1" className="fifth-part-illustration"/>
                <Flex vertical align="start" justify="center" gap={(props.isSmallScreen ? "middle" : "large")}>
                   <Typography.Text className="fifth-part-paragraph">{t("home.fifth-part.first-paragraph")}</Typography.Text>
                   <Typography.Text className="fifth-part-paragraph">{t("home.fifth-part.second-paragraph")}</Typography.Text>
                </Flex>
            </Flex>
            <Flex align="center" justify="center" gap={"large"} vertical={props.isSmallScreen}>
                <img src="/assets/images/landingPage/joinUs/img_2.jpg" alt="Illustration 2" className="fifth-part-illustration"/>
                <Flex vertical align="start" justify="center" gap={"large"}>
                    <Typography.Text className="fifth-part-paragraph">{t("home.fifth-part.third-paragraph")}</Typography.Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default HomePageFifthPart;