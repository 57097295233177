export const customTheme = {
  textColor: 'black',
  colorBackground: '#E6F7FF',
  colorPrimary: '#007BFF',
  lighterColorPrimary: '#73b2f5',
  colorPrimaryHover: 'rgba(0, 123, 255, 0.3)',
  colorSecondary: '#FFFFFF',
  colorContainer: '#FFFFFF',
  colorContainerBorder: '#F5F5F5',
  blueBackgroundTheme: '#007BFF',
  lighterBludBackgroundTheme: "#0EDFE0",
  gradientBackground: "linear-gradient(to right, var(--blueBackgroundTheme) 33%, var(--lighterBludBackgroundTheme) 100%)",
  titleFont: "'Fredoka', serif",
  landingPageTitleFontSize: "3em",
  landingPageTitleFontSizeSmall: "2em",
  landingPageFontSize: "1.5em",
  landingPageFontSizeSmall: "1.2em",
};

export const applyTheme = (theme: Record<string, string>) => {
  const root = document.documentElement;
  Object.keys(theme).forEach(key => {
    root.style.setProperty(`--${key}`, theme[key]);
  });
};

// Apply the theme
applyTheme(customTheme);