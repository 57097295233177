import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

type VetDetails = {
    vet_clinic: string;
    vet_name: string;
    vet_email: string;
    vet_phone_number: string;
}

/**
 * Fetches vet details for the user.
 * @param accessToken - The access token for authorization.
 * @returns The vet details or null if an error occurs.
 */
const getVetFromUser = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/vet`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

/**
 * Creates a new vet entry.
 * @param accessToken - The access token for authorization.
 * @param vet - The details of the vet to create.
 * @returns The response from the server or an error.
 */
const createVet = async (accessToken: string, vet: VetDetails) => {
    try {
        const data = JSON.stringify({vet}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/vet`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

/**
 * Updates an existing vet entry.
 * @param accessToken - The access token for authorization.
 * @param vetName - The name of the vet.
 * @param vetClinic - The clinic of the vet.
 * @param vetEmail - The email of the vet.
 * @param vetPhoneNumber - The phone number of the vet.
 * @param id - The ID of the vet to update.
 */
const updateVet = async (accessToken: string, vetName: string, vetClinic: string, vetEmail: string, vetPhoneNumber: string, id: string) => {
    try {
      const data = JSON.stringify({vet_name: vetName, vet_clinic: vetClinic, vet_email: vetEmail, vet_phone_number: vetPhoneNumber, id}, null, 1)
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/vet `,
        data,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
      );
    } catch (error: any) {
         console.log(`there was an error: ${error}`);
    }
}

/**
 * Deletes a vet entry.
 * @param accessToken - The access token for authorization.
 * @param id - The ID of the vet to delete.
 */
const deleteVet = async (accessToken: string, id: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/vet/${id}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
      );
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
    }
}

export {
    getVetFromUser,
    createVet,
    updateVet,
    deleteVet
}