import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as FamilyIcon } from "../../../assets/icons/Family.svg";
import { ReactComponent as PetReportIcon } from "../../../assets/icons/healthRecord/PetReport.svg";
import { ReactComponent as Veterinarian } from "../../../assets/icons/healthRecord/Veterinarian.svg";

import "./HomePageSecondPart.css";

function HomePageSecondPart(props) {
    const {t} = useTranslation();

    const parts = [
        {
            icon: <FamilyIcon className="icon"/>,
            title: "home.second-part.first-title",
            description: "home.second-part.first-description"
        },
        {
            icon: <PetReportIcon className="icon"/>,
            title: "home.second-part.second-title",
            description: "home.second-part.second-description"
        },
        {
            icon: <Veterinarian className="icon"/>,
            title: "home.second-part.third-title",
            description: "home.second-part.third-description"
        }
    ];

    return (
        <Flex className="home-page-second-part" align="center" vertical>
            <p className="part-title">{t("home.second-part.title")}</p>
            <Flex className="parts">
                {parts.map((part, index) => {
                    return (
                        <Flex key={index} className="part" vertical gap={"large"} align="center">
                            {part.icon}
                            <p className="title" style={{marginBottom: "5px"}}>{t(part.title)}</p>
                            <Typography.Text className="description">{t(part.description)}</Typography.Text>
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
}

export default HomePageSecondPart;