import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./HomePageSixthPart.css";

function HomePageSixthPart(props) {
    const {t} = useTranslation();

    const titleStyle = {
        ...props.titleStyle,
        margin: "0 20px"
    }

    return (
        <Flex className="home-page-sixth-part" align="center" justify="center" vertical gap={props.isSmallScreen ? 30 : 60}>
            <Typography.Title style={titleStyle}>{t("home.sixth-part.title")}</Typography.Title>
                {(props.isSmallScreen ? (
                    <Flex align="center" justify="center" vertical gap={"large"}>
                        <Flex align="center" justify="space-around" gap={"small"}>
                            <img src="/assets/images/landingPage/sponsor/semia.png" alt="Semia" className="sponsor-image-second"/>
                            <img src="/assets/images/landingPage/sponsor/bpiFrance.png" alt="Bpi France" className="sponsor-image-second"/>
                        </Flex>
                        <Flex align="center" justify="space-around" gap={"small"}>
                            <img src="/assets/images/landingPage/sponsor/laRegionGrandEst.png" alt="La Région Grand Est" className="sponsor-image-second"/>
                            <img src="/assets/images/landingPage/sponsor/questForChange.png" alt="Quest for Change" className="sponsor-image-second"/>
                        </Flex>
                        <img src="/assets/images/landingPage/sponsor/frenchTechEst.png" alt="French Tek Est" className="sponsor-image"/>
                    </Flex>
                ) : (
                    <Flex align="center" justify="center" vertical>
                        <Flex align="center" justify="space-evenly" style={{width: "100%"}}>
                            <img src="/assets/images/landingPage/sponsor/semia.png" alt="Semia" className="sponsor-image"/>
                            <img src="/assets/images/landingPage/sponsor/bpiFrance.png" alt="Bpi France" className="sponsor-image"/>
                            <img src="/assets/images/landingPage/sponsor/laRegionGrandEst.png" alt="La Région Grand Est" className="sponsor-image"/>
                        </Flex>
                        <Flex align="center" justify="space-around" style={{width: "50%"}} gap={"large"}>
                            <img src="/assets/images/landingPage/sponsor/questForChange.png" alt="Quest for Change" className="sponsor-image-second"/>
                            <img src="/assets/images/landingPage/sponsor/frenchTechEst.png" alt="French Tek Est" className="sponsor-image-second"/>
                        </Flex>
                    </Flex>
                ))}
            <Typography.Title style={titleStyle}>{t("home.sixth-part.description")}</Typography.Title>
        </Flex>
    )
}

export default HomePageSixthPart;