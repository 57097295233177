import React, { useEffect, useState } from 'react';
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import './healthRecord.css'
import InfoContainer from '../../component/infoContainer/InfoContainer';
import { HealthCardDrawer } from './drawer/HeatlhCardDrawer';
import { VetHistoryDrawer } from './drawer/VetHistoryDrawer';
import { ReminderDrawer } from './drawer/ReminderDrawer';
import { ReactComponent as PetProtection } from "../../assets/icons/healthRecord/PetProtection.svg";
import { ReactComponent as PetReport } from "../../assets/icons/healthRecord/PetReport.svg";
import { ReactComponent as Veterinarian } from "../../assets/icons/healthRecord/Veterinarian.svg";
import { useAuth0 } from '@auth0/auth0-react';
import { getEventsFromSelectedPet } from '../../request/events';
import { assignPetAvatar } from '../pets/PetAvatar';
import { useGlobalState } from '../../state/globalState';

export interface HealthDrawer {
  renew: () => void,
  onClose: () => void,
  events: any
}

function HealthSection() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const {t} = useTranslation();

  const [reRender, setReRender] = useGlobalState('reRender');

  const [openDrawer, setOpenDrawer] = useState<boolean[]>([]);
  const [petEvents, setPetEvents] = useState<any>();

  const sections = [
    {text: t("health-records-page.health-card"), svg: <PetReport/>},
    {text: t("health-records-page.veterinary-history"), svg: <Veterinarian/>},
    {text: t("health-records-page.prevention-vaccines"), svg: <PetProtection/>}
  ];

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  const onDrawerClose = () => {
    setTimeout(() => {
      setOpenDrawer(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
      })
    }, 250);
  }

  const getPetEvents = async() => {
    const response = await assignPetAvatar(await getEventsFromSelectedPet(await getAccessToken()), "pet.pet_id", "pet.avatar", true)
    const formatDate = (dateToFormat: string) => {
        const [date, time] = dateToFormat.split(' ');
        const [day, month, year] = date.split('-');

        return `${month}-${day}-${year} ${time || ""}`;
    }

    response.sort((a: any, b: any) => new Date(formatDate(a.date)).getTime() - new Date(formatDate(b.date)).getTime());
    setPetEvents(response);
  }

  useEffect(() => {
      getPetEvents();
  }, [reRender]);

  return (
    <>
      <InfoContainer>
        <Flex justify='center' className='health-record-section-container' style={{ flexDirection: 'row', height: '180px', alignItems: 'center' }} gap={40}>
          {sections.map((section: any, key: number) => (
            <Flex
              key={key}
              className='health-record-btn'
              justify='center'
              style={{ height: '90%', width: '130px',flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                setOpenDrawer(prevState => {
                  const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = false;
                      return acc;
                  }, {});
                  newState[key] = !openDrawer[key];
                  return newState;
                })
              }}
              gap={5}
            >
              <Flex style={{ maxWidth: '89px', width: '100%' }}>
                {section.svg}
              </Flex>
              <Typography.Text className='health-record-btn-text' style={{ maxWidth: '85px'}} strong>{section.text}</Typography.Text>
            </Flex>
          ))}
        </Flex>
      </InfoContainer>
      {openDrawer[0] && <HealthCardDrawer onClose={onDrawerClose}/>}
      {openDrawer[1] && <VetHistoryDrawer onClose={onDrawerClose} events={petEvents} renew={getPetEvents}/>}
      {openDrawer[2] && <ReminderDrawer onClose={onDrawerClose} events={petEvents} renew={getPetEvents}/>}
    </>
  );
}

export default HealthSection;
