import React, { createRef, useEffect, useRef, useState } from "react";
import { Card, Flex, Typography } from "antd";
import { IoPaw } from "react-icons/io5";
import { motion } from 'framer-motion'

import { customTheme } from "../../config/theme";
import { useGlobalState } from "../../state/globalState";
import { getUserScore } from "../../request/user/user";
import { useAuth0 } from "@auth0/auth0-react";
import { useAnimationContext } from "../../contexts/Animation";

interface ScoreDisplayProps {
    setScoreRefCoord: React.Dispatch<React.SetStateAction<{x: number, y: number}>>,
}

function ScoreDisplay(props: ScoreDisplayProps) {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const [reRenderUserScore, setReRenderUserScore] = useGlobalState('reRenderUserScore');

    const {startAnimation} = useAnimationContext()

    const scoreRef = useRef<null | HTMLDivElement>(null);

    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0)

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const renewScore = async (show: boolean) => {
        const result = await getUserScore(await getAccessToken())

        if (show) {
            setScore(result)
        } else {
            return result;
        }
    }

    useEffect(() => {
        renewScore(true);
        setShowScore(false)
    }, [])

    useEffect(() => {
        if (reRenderUserScore) {
            setShowScore(true)
            if (scoreRef.current) {
                const {x, y} = scoreRef.current.getBoundingClientRect();
                props.setScoreRefCoord({x, y});
            }
        }
    }, [reRenderUserScore])

    const addUpToScore = async () => {
        const newScore = await renewScore(false);
        let scoreToAdd = newScore - score;

        setTimeout(() => {
            const interv = 500 / scoreToAdd;

            const interval = setInterval(() => {
                setScore(score => score + 1);
                scoreToAdd--;
                if (scoreToAdd <= 0) {
                    stopCounter()
                }
            }, interv);

            const stopCounter = () => {
                clearInterval(interval);
            }
        }, 1700);
    }

    useEffect(() => {
        if (!startAnimation) {
            setTimeout(() => {
                setShowScore(false)
            }, 1000);
        }
        if (startAnimation) {
            addUpToScore();
        }
    }, [startAnimation])

    return (
        <>
            <Flex
                justify="end" align="center"
                style={{
                    height: '70px', backgroundColor: 'rgba(197, 194, 194, 0)',
                    visibility: 'hidden', position: 'absolute', right: '15px', zIndex: 2
                }}
            >
                <Flex style={{ border: 'solid calc(1px * 1.3) #084687', borderRadius: 'calc(7px + 1px * 0.5)'}}>
                    <Flex
                        style={{
                            minWidth: '100px', width: 'auto', height: '40px',
                            backgroundColor: customTheme.colorPrimary,
                            border: `solid 2px #0c65c4`, borderRadius: '6px',
                            boxShadow: "2px 3px 0 #084687"
                        }}
                        className="point-container"
                        justify="center"
                    >
                        <Flex align="center" gap={8} style={{ marginLeft: '5px', marginRight: '5px' }}>
                            <Flex ref={scoreRef}>
                                <IoPaw style={{ color: 'white', fontSize: '20px', transform: 'rotate(-30deg)'}}/>
                            </Flex>
                            <Typography.Text className="score"  style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>{score}</Typography.Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <>
                <Flex
                    justify="end" align="center"
                    style={{
                        height: '70px', backgroundColor: 'rgba(197, 194, 194, 0)',
                        position: !showScore ? 'relative' : 'fixed', right: '15px', zIndex: 2
                    }}
                >
                    <Flex style={{ border: 'solid calc(1px * 1.3) #084687', borderRadius: 'calc(7px + 1px * 0.5)'}}>
                        <Flex
                            style={{
                                minWidth: '100px', width: 'auto', height: '40px',
                                backgroundColor: customTheme.colorPrimary,
                                border: `solid 2px #0c65c4`, borderRadius: '6px',
                                boxShadow: "2px 3px 0 #084687"
                            }}
                            className="point-container"
                            justify="center"
                        >
                            <Flex align="center" gap={8} style={{ marginLeft: '5px', marginRight: '5px' }}>
                                <Flex>
                                    <IoPaw style={{ color: 'white', fontSize: '20px', transform: 'rotate(-30deg)'}}/>
                                </Flex>
                                <Typography.Text className="score"  style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>{score}</Typography.Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {showScore &&
                    <div style={{ height: '70px' }}/>
                }
            </>
        </>
    );
}

export default ScoreDisplay;
