import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./HomePageFirstPart.css";
import AccessAppButton from "../AccessAppButton/AccessAppButton";

function HomePageFirstPart(props) {
    const {t} = useTranslation();

    return (
        <Flex className="home-page-first-part" justify="space-between" align="center" vertical>
            <Typography.Title style={props.titleStyle}>{t("home.slogan")}</Typography.Title>
            {props.isSmallScreen && <AccessAppButton small/>}
        </Flex>
    )
}

export default HomePageFirstPart;