import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatButton } from 'antd';
import _ from 'lodash';

import './home.css'
import { customTheme } from '../../config/theme';
import HomePageHeader from '../../component/home/HomePageHeader';
import HomePageFirstPart from '../../component/home/firstPart/HomePageFirstPart';
import HomePageSecondPart from '../../component/home/secondPart/HomePageSecondPart';
import HomePageThirdPart from '../../component/home/thirdPage/HomePageThirdPart';
import HomePageFourthPart from '../../component/home/fourthPage/HomePageFourthPart';
import HomePageFifthPart from '../../component/home/fifthPart/HomePageFifthPart';
import HomePageSixthPart from '../../component/home/sixthPart/HomePageSixthPart';
import HomePageSeventhPart from '../../component/home/seventhPart/HomePageSeventhPart';

function Home() {
  const {t} = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const titleStyle = {
    fontFamily: customTheme.titleFont,
    textAlign: 'center',
    fontSize: (isSmallScreen ? customTheme.landingPageTitleFontSizeSmall : customTheme.landingPageTitleFontSize),
  }

  useEffect(() => {
      const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HomePageHeader isSmallScreen={isSmallScreen}/>
      <HomePageFirstPart titleStyle={titleStyle} isSmallScreen={isSmallScreen}/>
      <HomePageSecondPart titleStyle={titleStyle}/>
      <HomePageThirdPart titleStyle={{...titleStyle, textAlign:"left"}} isSmallScreen={isSmallScreen}/>
      <HomePageFourthPart titleStyle={{...titleStyle}} isSmallScreen={isSmallScreen}/>
      <HomePageFifthPart titleStyle={titleStyle} isSmallScreen={isSmallScreen}/>
      <HomePageSixthPart titleStyle={titleStyle} isSmallScreen={isSmallScreen}/>
      <HomePageSeventhPart titleStyle={{...titleStyle, textAlign: isSmallScreen ? "center" : "left", color: "white"}} isSmallScreen={isSmallScreen}/>
      <FloatButton.BackTop/>
    </>
  );
}

export default Home;