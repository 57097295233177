import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Divider, Flex, message, Popconfirm, Typography } from "antd";

import './family.css'
import familyRequest from "../../request/family";
import AddingModal from "../profile/modals/AddingModal";
import DisplayMember from "./familyDisplay/DisplayMember";
import AddingPetModal from "../profile/modals/AddingPetModal";
import DisplayPet from "./familyDisplay/DisplayPet";
import FamilySettingsModal from "../profile/modals/FamilySettingsModal";
import { createInvitation } from "../../request/invitation";

import { useAuth0 } from "@auth0/auth0-react";
import { DownOutlined, PlusOutlined, RightOutlined, SettingOutlined, UpOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../state/globalState";

interface Member {
    firstname: string,
    lastname: string,
    email: string,
    id: string
}

interface Pet {
    name: string,
    species: string,
    breed: string,
    id: string,
    avatar: string,
    type: string
}

interface Family {
    members?: Member[],
    pets?: Pet[],
    owner?: string,
    name?: string,
    id: string
}

function SingleFamilyDisplay({ family, getFamilies, user }: { family: Family, getFamilies: () => void, user: any }) {
    const [newMemberModalOpen, setNewMemberModalOpen] = useState<boolean>(false);
    const [newPetModalOpen, setNewPetModalOpen] = useState<boolean>(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const {t} = useTranslation();
    const [hidden, setHidden] = useState<boolean>(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

    const getAccessToken = async () => {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                    scope: "openid profile email",
                },
            });
            return accessToken;
        }
        return "";
    }

    async function handleNewMember(email: string) {
        const response = await createInvitation(await getAccessToken(), family.id, email);
        if (response.status === 404)
            return message.error(t("family.requests.new-member-not-found"));
        if (response.status === 403)
            return message.error(t("family.requests.new-member-already"));
        setNewMemberModalOpen(false);
        message.success(t("family.requests.new-member-success"));
        getFamilies();
    }

    async function handleNewPet(pets_id: string[]) {
        for (const pet_id of pets_id) {
            const response = await familyRequest.addPetToFamily(await getAccessToken(), family.id, pet_id);
            if (response.status === 404) {
                message.error(t("family.requests.new-pet-not-found"));
                continue;
            }
            if (response.status === 403) {
                message.error(t("family.requests.new-pet-already"));
                continue;
            }
            if (response.status !== 200) {
                message.error(t("family.requests.new-pet-error"));
                continue;
            }
        }
        message.success(t("family.requests.new-pet-success"));
        setNewPetModalOpen(false);
        getFamilies();
    }

    async function removeMemberFromFamily(member: Member) {
        const response = await familyRequest.removeMemberFromFamily(await getAccessToken(), family.id, member.email);
        if (response.status === 404)
            return message.error(t("family.requests.remove-member-not-found"));
        if (response.status === 403)
            return message.error(t("family.requests.remove-member-owner"));
        if (response.status != 200)
            return message.error(t("family.requests.remove-member-error"));
        message.success(t("family.requests.remove-member-success"));
        getFamilies();
    }

    async function removePetFromFamily(pet: Pet) {
        const response = await familyRequest.removePetFromFamily(await getAccessToken(), family.id, pet.id);
        if (response.status === 404)
            return message.error(t("family.requests.remove-pet-not-found"));
        if (response.status != 200)
            return message.error(t("family.requests.remove-pet-error"));
        message.success(t("family.requests.remove-pet-success"));
        getFamilies();
    }

    async function deleteFamily() {
        const response = await familyRequest.deleteFamily(await getAccessToken(), family.id);
        if (response.status === 404)
            return message.error(t("family.requests.delete-not-found"));
        if (response.status != 200)
            return message.error(t("family.requests.delete-error"));
        message.success(t("family.requests.delete-success"));
        getFamilies();
    }

    async function updateFamily(values: any) {
        if (values.name || values.owner) {
            const response = await familyRequest.updateFamily(await getAccessToken(), family.id, values.name || family?.name, values.owner || family?.owner);
            if (response.status === 404)
                return message.error(t("family.requests.update-not-found"));
            if (response.status != 200)
                return message.error(t("family.requests.update-error"));
            message.success(t("family.requests.update-success"));
            getFamilies();
        }
        setSettingsModalOpen(false);
    }

    useEffect(() => {
        setIsOwner(user._id == family?.owner);
    }, [family]);

    return (
        <div style={{ marginLeft: '15px', marginRight: '15px' }}>
            <Flex onClick={() => setHidden(!hidden)} style={{width: "100%"}}>
                <Divider style={{ cursor: 'pointer' }}>
                    <Flex justify='space-between' align='center' gap={5}>
                        {hidden ? <RightOutlined style={{ fontSize: "15px" }}/> : <DownOutlined style={{ fontSize: "15px" }}/>}
                        {family?.name}
                    </Flex>
                </Divider>
            </Flex>
            <div className={`family-details ${hidden ? 'hidden' : 'visible'}`}>
                <Card style={{ width: '100%' }}>
                    <Flex justify='space-between' align='center'>
                        <Typography.Title level={4}>{t("family.family-members")}</Typography.Title>
                        {isOwner &&
                            <Button
                                type="primary"
                                onClick={() => setNewMemberModalOpen(true)}
                                icon={<PlusOutlined style={{ fontSize: '15px' }}/>}
                                className="add-button"
                            >
                                {!footerVisibility && t("family.add-modal.title")}
                            </Button>
                        }
                        <AddingModal
                            title={t("family.add-modal.title")}
                            open={newMemberModalOpen}
                            onCancel={() => setNewMemberModalOpen(false)}
                            onOk={handleNewMember}
                            description={t("family.add-modal.description")}
                            placeholder="Email"
                        />
                    </Flex>
                    <Flex justify="center" vertical>
                        {family?.members?.map((member, index) => (
                            <div key={index}>
                                <Flex justify='space-between' align='center' style={{marginTop: "15px"}}>
                                    <DisplayMember member={member} />
                                    {isOwner && (
                                        <Popconfirm
                                            placement="bottom"
                                            title={t("family.remove-title", {name: member.firstname})}
                                            description={t("family.remove-description")}
                                            onConfirm={() => removeMemberFromFamily(member)}
                                            okText={t("family.yes")}
                                            cancelText={t("family.no")}
                                        >
                                            <Button type="default" danger>{t("family.remove")}</Button>
                                        </Popconfirm>
                                    )}
                                </Flex>
                                {family.members && index !== family.members.length - 1 && <Divider style={{marginTop: "15px", marginBottom: "0px"}}/>}
                            </div>
                        ))}
                    </Flex>
                </Card>
                <Card style={{ width: '100%', marginTop: '15px' }}>
                    <Flex justify='space-between' align='center'>
                        <Typography.Title level={4}>{t("family.family-pets")}</Typography.Title>
                        {isOwner &&
                            <Button
                                type="primary"
                                onClick={() => setNewPetModalOpen(true)}
                                icon={<PlusOutlined style={{ fontSize: '15px' }}/>}
                                className="add-button"
                            >
                                {!footerVisibility && t("family.add-pet.title")}
                            </Button>
                        }
                        <AddingPetModal
                            title={t("family.add-pet.title")}
                            open={newPetModalOpen}
                            onCancel={() => setNewPetModalOpen(false)}
                            onOk={handleNewPet}
                            description={t("family.add-pet.description")}
                            familyPets={family?.pets?.map(pet => pet.id) || []}
                        />
                    </Flex>
                    <Flex justify="center" vertical>
                        {family?.pets?.map((pet, index) => (
                            <div key={index}>
                                <Flex justify='space-between' align='center' style={{marginTop: "15px"}}>
                                    <DisplayPet pet={pet}/>
                                    {isOwner && (
                                        <Popconfirm
                                            placement="bottom"
                                            title={t("family.remove-title", {name: pet.name})}
                                            description={t("family.remove-description")}
                                            onConfirm={() => removePetFromFamily(pet)}
                                            okText={t("family.yes")}
                                            cancelText={t("family.no")}
                                        >
                                            <Button type="default" danger>{t("family.remove")}</Button>
                                        </Popconfirm>
                                    )}
                                </Flex>
                                {family.pets && index !== family.pets.length - 1 && <Divider style={{marginTop: "15px", marginBottom: "0px"}}/>}
                            </div>
                        ))}
                    </Flex>
                </Card>
                <Flex justify="space-between" style={{marginTop: "10px", width: "100%"}}>
                    {isOwner &&
                        <>
                            <Button
                                type="default"
                                icon={<SettingOutlined />}
                                onClick={() => setSettingsModalOpen(true)}
                            >
                                {!footerVisibility && t("family.settings.title")}
                            </Button>
                            <FamilySettingsModal open={settingsModalOpen} onCancel={() => setSettingsModalOpen(false)} onOk={updateFamily} family={family} />
                            <Popconfirm
                                placement="bottom"
                                title={t("family.delete-title") + " ?"}
                                description={t("family.remove-description")}
                                onConfirm={deleteFamily}
                                okText={t("family.yes")}
                                cancelText={t("family.no")}
                            >
                                <Button type="default" danger>{t("family.delete-title")}</Button>
                            </Popconfirm>
                        </>
                    }
                </Flex>
            </div>
        </div>
    )
}

export default SingleFamilyDisplay;