import React from "react";
import { Button, Card, Flex, Typography, message } from "antd";

import { Invitation } from "../profile/ProfileDisplay";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getInvitation, validateInvitation } from "../../request/invitation";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../../state/globalState";

interface FamilyInvitationProps {
    invitations: Invitation[]
}

function FamilyInvitation(props: FamilyInvitationProps) {
    const {t} = useTranslation();
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const [reRender, setReRender] = useGlobalState('reRender');
    const [invitations, setInvitations] = useGlobalState('invitations');

    const getAccessToken = async () => {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                    scope: "openid profile email",
                },
            });
            return accessToken;
        }
        return "";
    }

    const handleValidation = async (invitationId: string, accept: boolean) => {
        try {
            await validateInvitation(await getAccessToken(), invitationId, accept);

            setReRender(new Date())
            if (accept) {
                message.success(t('family.invitation.success-accept'))
            } else {
                message.success(t('family.invitation.success-decline'))
            }
            getUserInvitations();
        } catch (error: any) {
            message.error(t('family.invitation.error'))
        }
    }

    const getUserInvitations = async () => {
        const result = await getInvitation(await getAccessToken());

        setInvitations(result);
    }

    return (
        <Flex style={{ maxWidth: '800px', width: '100%', padding: "0 15px"}} vertical gap={"small"}>
            {props.invitations.map((invitation, key) => (
                <Card key={key} style={{ width: '100%'}}>
                    <Typography.Text>
                        {t('family.invitation.got-invite-in')}
                        {' '}
                        <span style={{ fontWeight: "bold" }}>
                            {invitation.family.name}
                        </span>
                        {" " + t('family.invitation.by') + " "}
                        <span style={{ fontWeight: "bold" }}>
                            {invitation.sender.firstname} ({invitation.sender.email})
                        </span>
                    </Typography.Text>
                    <Flex justify="space-evenly" style={{ height: 'auto', margin: "5px 0", alignItems: 'center' }}>
                        <Button
                            icon={<CheckOutlined style={{ color: 'green' }}/>}
                            onClick={() => handleValidation(invitation._id, true)}
                        >
                            {t('family.invitation.accept')}
                        </Button>
                        <Button
                            icon={<CloseOutlined style={{ color: 'red' }}/>}
                            onClick={() => handleValidation(invitation._id, false)}
                        >
                            {t('family.invitation.decline')}
                        </Button>
                    </Flex>
                </Card>
            ))}
        </Flex>
    );
}

export default FamilyInvitation