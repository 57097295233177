import { Button, Card, ConfigProvider, Divider, Flex, Form, Input, Layout, List, Popover, Space, Tabs, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined, ExclamationCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

import './profile.css'
import { updateUser } from '../../request/user/user';
import VetsDisplay from './VetsDisplay';
import { customTheme } from '../../config/theme';
import EmailModal from './modals/EmailModal';
import ChangeLang from '../lang/ChangeLang';
import { useTranslation } from 'react-i18next';
import { logout as logoutRequest } from '../../request/auth';
import { useGlobalState } from '../../state/globalState';
import FeedbackButton from '../utils/FeedbackButton';
import FamilyDisplay from '../family/FamilyDisplay';
import { getInvitation } from '../../request/invitation';

export interface Invitation {
    _id: string
    family: {
        name: string
    },
    sender: {
        firstname: string
        lastname: string
        email: string
    }
}

function ProfileDisplay(props: any) {
    const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');
    const [reRender, setReRender] = useGlobalState('reRender');

    const [form] = Form.useForm();
    const [formEmail] = Form.useForm();

    const user = props.user;

    const [save, setSave] = useState<boolean>(false);
    const [disableSave, setDisableSave] = useState(false);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [emailModalVisibility, setEmailModalVisibility] = useState(false);

    const [invitations, setInvitations] = useGlobalState('invitations');

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleSubmitProfile = async () => {
        try {
            const result = await updateUser(await getAccessToken(),
                firstname !== "" ? firstname : user.firstname,
                lastname !== "" ? lastname : user.lastname,
                phoneNumber !== "" ? phoneNumber : (user?.phone_number || "")
            );
            if (result && result?.status === 200 && result?.data.msg === 'User updated') {
                message.success(t("my-profile.success"));
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to modify user information') {
                message.error(t("my-profile.error"));
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

  return (
    <ConfigProvider
        theme={{
            components: {
                Tabs: {
                    itemSelectedColor: customTheme.colorPrimary,
                    inkBarColor: customTheme.colorPrimary,
                    itemHoverColor: 'rgba(0, 123, 255, 0.8)',
                    itemColor: 'black'
                },
            }
        }}
    >
        <Layout>
            <Layout.Content style={{ backgroundColor: '#E6F7FF' }}>
                    <Tabs
                        centered
                        className='profile-tabs'
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: t("my-profile.title"),
                                children:
                                    <>
                                        {user &&
                                            <div style={{maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <Divider className='profil-divider'>{t("my-profile.my-profile")}</Divider>
                                                <Form
                                                    form={form}
                                                    layout='vertical'
                                                    onFinish={() => handleSubmitProfile()}
                                                    style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                                                    initialValues={{ firstname: user.firstname, lastname: user.lastname, phone_number: user.phone_number || ""}}
                                                >
                                                    <div className='profile-container-responsive' style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name="firstname"
                                                            label={t("my-profile.firstname")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        >
                                                            <Input
                                                                style={{ borderColor: user.firstname == "" ? 'rgb(242, 73, 78)' : '' }}
                                                                value={firstname}
                                                                onChange={(e) => {
                                                                    setDisableSave(e.target.value == '')
                                                                    setFirstname(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div style={{ width: '4%' }}/>
                                                        <Form.Item
                                                            name="lastname"
                                                            label={t("my-profile.lastname")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        >
                                                            <Input
                                                                style={{ borderColor: user.lastname == "" ? 'rgb(242, 73, 78)' : '' }}
                                                                value={lastname}
                                                                onChange={(e) => {
                                                                    setDisableSave(e.target.value == '')
                                                                    setLastname(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='profile-container-responsive' style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name="phone_number"
                                                            label={t("my-profile.phone-number")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                        >
                                                            <Input
                                                                value={phoneNumber}
                                                                onChange={(e) => {
                                                                    setPhoneNumber(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div style={{ flexGrow: 1, alignContent: 'center'}}>
                                                            <ChangeLang/>
                                                        </div>
                                                    </div>
                                                </Form>
                                                {user.method == "auth0" &&
                                                    <>
                                                        <Divider className='profil-divider'>{t("my-profile.personal-information")}</Divider>
                                                        <Form
                                                            form={formEmail}
                                                            layout='vertical'
                                                            className='profile-container-responsive'
                                                            style={{ display: 'flex', padding: '10px' }}
                                                            initialValues={{ email: user.email, password: '********' }}
                                                        >
                                                            <Form.Item
                                                                name="email"
                                                                label={t("my-profile.email")}
                                                                rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                                className='profile-content-responsive'
                                                                style={{ width: '48%' }}
                                                            >
                                                                <Space.Compact style={{ width: '100%' }}>
                                                                    <Input
                                                                        value={email === "" ? user.email : email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        style={{ width: '100%', backgroundColor: 'white', color: 'black', cursor: 'default' }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button style={{ width: '1%' }} onClick={() => setEmailModalVisibility(true)}><EditOutlined /></Button>
                                                                </Space.Compact>
                                                            </Form.Item>
                                                        </Form>
                                                    </>
                                                }
                                                <div style={{ width: '4%' }}/>
                                                {emailModalVisibility &&
                                                    <EmailModal onFinish={(email: string) => {
                                                        setEmailModalVisibility(false);
                                                        if (email)
                                                            setEmail(email);
                                                    }}/>
                                                }
                                                <Flex style={{ padding: '10px', alignItems: 'center' }} justify='start' gap={30}>
                                                    <Tooltip title={t("my-profile.logout")}>
                                                        <Button
                                                            type="default"
                                                            danger
                                                            icon={<LogoutOutlined />}
                                                            size='large'
                                                            onClick={async () => {
                                                                await logoutRequest();
                                                                await logout({logoutParams: {returnTo: `${window.origin}/`}})
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {footerVisibility &&
                                                        <FeedbackButton/>
                                                    }
                                                </Flex>
                                                {save &&
                                                    <Button
                                                        type="primary"
                                                        style={{ fontSize: '18px', position: 'fixed', bottom: '25px', right: '25px', zIndex: '500' }}
                                                        onClick={() => {
                                                            form.submit();
                                                            setSave(false)
                                                        }}
                                                        disabled={disableSave}
                                                    >
                                                        {t("form.save")}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </>
                            },
                            {
                                key: '2',
                                label: t("vet-profile.title"),
                                children:
                                    <VetsDisplay/>
                            },
                            {
                                key: '3',
                                label: t("family.title"),
                                icon: invitations.length >= 1 && <ExclamationCircleOutlined style={{ color: 'red', marginRight: '-5px' }}/>,
                                children:
                                    <FamilyDisplay user={user} invitations={invitations}/>
                            }
                        ]}
                    />
            </Layout.Content>
        </Layout>
    </ConfigProvider>
  );
}

export default ProfileDisplay;
