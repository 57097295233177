import React, { useEffect, useState } from 'react';
import { Form, Button, ConfigProvider, Modal, Input, Dropdown, Space, Select, Radio, InputNumber, Divider, Flex } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import { createPet } from '../../../request/pets';
import { DogBreed, CatBreed } from './breed';
import { getVetFromUser } from '../../../request/vet';
import AddVets from '../../profile/AddVets';
import { useGlobalState } from '../../../state/globalState';
import { dateFormat } from '../../../config/format';
import ImageUploader, { Image, defaultImage } from '../../imageUploader/ImageUploader';
import { createImageFromUrl } from '../../../request/images';
import CustomDatePicker from '../../customPicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';


function PetsModal({onSubmit, onClose}: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');
    const [form] = Form.useForm();

    const [avatar, setAvatar] = useState<Image>(defaultImage);

    const [name, setName] = useState<string>("");
    const [species, setSpecies] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [breed, setBreed] = useState<string>("");
    const [neutered, setNeutered] = useState(false);
    const [birthDate, setBirthDate] = useState<dayjs.Dayjs>(dayjs());
    const [petIdNumber, setPetIdNumber] = useState<number>(0);
    const [passportNumber, setPassportNumber] = useState<string>("")
    const [medicalHistory, setMedicalHistory] = useState("");
    const [vet, setVet] = useState("");
    const [breedOptions, setBreedOptions] = useState<{label: string, value: string}[]>([]);
    const [breedOpen, setBreedOpen] = useState(false);

    const [vets, setVets] = useState<{_id: string, vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string}[]>();

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleSubmit = async () => {
        const date = new Date(birthDate.toDate()).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        try {
            const image = {mimeType: avatar.mimeType, size: avatar.size, file: ""};

            const response = await createPet(
                await getAccessToken(),
                {name, species, gender, breed, neutered, date, petIdNumber, passportNumber, vet: {vet_id: vet}, medicalHistory, image});
            if (response.url != "") {
                await createImageFromUrl(response.url, avatar.mimeType, avatar.file);
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        onSubmit();
        setReRenderSelectPet(new Date());
    }

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    const gatherUserVets = async () => {
        setVets(await getVetFromUser(await getAccessToken()));
    }

    useEffect(() => {
        gatherUserVets()
    }, [])

    return (
        <div className="PetsModal">
        <Modal
            open={true}
            height={'auto'}
            title={t('pet-profile.add-animal')}
            okText={t("form.add")}
            cancelText={t("form.cancel")}
            onCancel={() => onClose()}
            onOk={() => {
                    form.submit();
                }
            }
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    variant='filled'
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Flex justify='start' style={{ marginBottom: '15px', flexDirection: 'row', alignItems: 'center' }} gap={30}>
                <ImageUploader setImage={setAvatar} showPreview crop/>
                <Form.Item
                    label={t("form.name")}
                    name="name"
                    rules={[{ required: true, message: t("form.name-placeholder") }]}
                    style={{ flexGrow: 1 }}
                >
                    <Input placeholder={t("form.name-placeholder")} onChange={(e) => setName(e.target.value)}/>
                </Form.Item>
            </Flex>
            <Form.Item
                label={t("pet-profile.species")}
                name="species"
                rules={[{ required: true, message: t("pet-profile.select-species") }]}
            >
                <Select placeholder={t("pet-profile.select-species")} onSelect={(e) => setSpecies(e)}>
                    <Select.Option value="dog">{t("pet-profile.dog")}</Select.Option>
                    <Select.Option value="cat">{t("pet-profile.cat")}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label={t("pet-profile.gender")}
                name="gender"
                rules={[{ required: true, message: t("pet-profile.select-gender") }]}
            >
                <Select placeholder={t("pet-profile.select-gender")} onSelect={(e, object) => setGender((object as any).children)}>
                    <Select.Option value="male">{t("pet-profile.male")}</Select.Option>
                    <Select.Option value="female">{t("pet-profile.female")}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="breed"
                label={t("pet-profile.breed")}
                rules={[{ required: true, message: t("pet-profile.breed-select")}]}
            >
                <Select
                    disabled={species === ''}
                    showSearch
                    placeholder={species === '' ? t("pet-creation.select-species-first") : t("pet-profile.breed-select")}
                    optionFilterProp="label"
                    options={breedOptions}
                    onChange={(e) => {
                        setBreed(e);
                        setBreedOpen(false);
                    }}
                    open={breedOpen}
                    onSearch={(value) => {
                        if (value.length >= 2) {
                            setBreedOptions(species === 'cat' ? CatBreed : DogBreed);
                            setBreedOpen(true);
                        } else {
                            setBreedOptions([]);
                            setBreedOpen(false);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item
                name="neutered"
                rules={[{ required: true, message: '' }]}
            >
                <Radio.Group onChange={(e) => setNeutered(e.target.value)}>
                    <Radio value={true}>{t("pet-profile.sprayed")}</Radio>
                    <Radio value={false}>{t("pet-profile.unsterilized")}</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={t("pet-profile.birth-date")}
                name="birth_date"
                rules={[{ required: true, message: t("pet-creation.birth-date-placeholder") }]}
            >
                <CustomDatePicker
                    disabledDate={disableFutureDates}
                    placeholder={t("pet-creation.birth-date-placeholder")}
                    style={{ width: '100%' }}
                    onChange={(e: any) => setBirthDate(e as any)}
                    format={`${dateFormat}`}
                />
            </Form.Item>
            <Form.Item
                label={t("pet-profile.vet")}
                name="vet"
            >
                <Select
                    placeholder={t("pet-profile.vet-select")}
                    onSelect={(e) => setVet(e)}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddVets renew={gatherUserVets} backgroundColor='white'/>
                        </>
                    )}
                    options={vets && vets.map((val) => ({
                        label: `Dr. ${val.vet_name} ${val.vet_name != "" && val.vet_clinic != "" ? ' - ' : ''}  ${val.vet_clinic}`,
                        value: val._id
                    }))}
                />
            </Form.Item>
            <Form.Item
              label={t("pet-profile.chip-id")}
              name="petIdNumber"
              style={{ position: 'relative', top: '100%' }}
            >
              <InputNumber
                placeholder={t("pet-creation.chip-id-placeholder")}
                min={0}
                style={{ width: '100%' }}
                onChange={(e: number | any) => setPetIdNumber(e)}
              />
            </Form.Item>
            <Form.Item
              label={t("pet-profile.passport-number-long")}
              name="petPassportNumber"
              style={{ position: 'relative', top: '100%' }}
            >
              <Input
                placeholder={t("pet-creation.passport-number-placeholder")}
                style={{ width: '100%' }}
                onChange={(e: any) => setPassportNumber(e.target.value)}
              />
            </Form.Item>
            <Form.Item
                label={t("pet-profile.health-history")}
                name="medical_history"
            >
                <TextArea rows={2} placeholder={t("pet-creation.health-history-placeholder")} onChange={(e) => setMedicalHistory(e.target.value)} maxLength={300}/>
            </Form.Item>
        </Modal>
    </div>
  );
}

export default PetsModal;