import React, { useCallback, useEffect, useState } from 'react';

import Behaviors from '../../component/behavior/Behaviors';
import Activity from '../../component/activity/Activity';
import DisplayWeight from '../../component/weight/DisplayWeight';
import ComingEvents from '../../component/calendar/comingEvents/ComingEvents';
import { useGlobalState } from '../../state/globalState';
import { Flex, Typography } from 'antd';
import AddPet from '../../component/pets/AddPet';
import { useTranslation } from 'react-i18next';
import ScoreDisplay from '../../component/score/ScoreDisplay';
import { AnimationContext } from '../../contexts/Animation';
import Animation from '../../component/score/Animation';

function Application() {
  const {t} = useTranslation();

  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');

  const [startAnimation, setStartAnimation] = useState(false)
  const [animationCoord, setAnimationCoord] = useState({x: 0, y: 0})
  const [scoreRefCoord, setScoreRefCoord] = useState({x: 0, y: 0})

  return (
    <>
      {numberOfPets >= 1
        ? <AnimationContext.Provider value={{startAnimation, setStartAnimation, animationCoord, setAnimationCoord}}>
          <div>
            {startAnimation
             ?  <Animation scoreCoord={scoreRefCoord}/>
             :  null
            }
            <ScoreDisplay setScoreRefCoord={setScoreRefCoord}/>
          </div>
            <ComingEvents/>
            <Behaviors/>
            <DisplayWeight/>
            <Activity/>
          </AnimationContext.Provider>
        : <Flex
            justify='center'
            style={{ flexDirection: 'column', alignItems: 'center', height: '50vh' }}
            gap={5}
          >
            <Typography.Title
              level={1}
              style={{ fontWeight: '300', fontFamily: 'Montserrat, sans-serif', margin: 0 }}
            >
              {t("first-connection.welcome")}
            </Typography.Title>
            <Typography.Text
              style={{ fontSize: '22px', fontFamily: 'Montserrat, sans-serif', width: '200px', textAlign: 'center', fontWeight: '300' }}
            >
              {t("first-connection.add-pet")}
            </Typography.Text>
            <Flex style={{  }}>
              <AddPet setStyle={false}/>
            </Flex>
          </Flex>
      }
    </>
  );
}

export default Application;
