import { Flex } from "antd";
import Typography from "antd/es/typography/Typography";

import "./HomePageThirdPart.css";
import { useTranslation } from "react-i18next";
import AccessAppButton from "../AccessAppButton/AccessAppButton";
import { ReactComponent as CommunityPathImageEnHor } from "../../../assets/images/landingPage/communityPath/horizontal/en.svg";
import { ReactComponent as CommunityPathImageEnVer } from "../../../assets/images/landingPage/communityPath/vertical/en.svg";
import { ReactComponent as CommunityPathImageFrHor } from "../../../assets/images/landingPage/communityPath/horizontal/fr.svg";
import { ReactComponent as CommunityPathImageFrVer } from "../../../assets/images/landingPage/communityPath/vertical/fr.svg";

function HomePageThirdPart(props) {
    const { t, i18n } = useTranslation();

    const titleStyle = {
        ...props.titleStyle,
        ...(props.isSmallScreen ? {textAlign: "center"} : {}),
    }

    function getCommunityPathImage() {
        if (i18n.language === "fr") {
            return props.isSmallScreen ? <CommunityPathImageFrVer/> : <CommunityPathImageFrHor/>;
        } else {
            return props.isSmallScreen ? <CommunityPathImageEnVer/> : <CommunityPathImageEnHor/>;
        }
    }

    return (
        <Flex vertical align="center" justify="center" className="home-page-third-part">
            <Flex align="start" justify="center" gap={"large"} vertical={props.isSmallScreen}>
                <Flex vertical align="start" justify="flex-start" gap={(props.isSmallScreen ? "small" : "large")} style={{width: (props.isSmallScreen ? "100%" : "75%")}}>
                    <Typography.Title style={titleStyle}>{t("home.third-part.title")}</Typography.Title>
                    <Typography.Text className="third-part-paragraph">{t("home.third-part.first-paragraph")}</Typography.Text>
                    <Typography.Text className="third-part-paragraph">{t("home.third-part.second-paragraph")}</Typography.Text>
                    <Typography.Text className="third-part-paragraph">{t("home.third-part.third-paragraph")}</Typography.Text>
                </Flex>
                <Flex vertical align="center" justify="start" gap={"large"}>
                    <img src={`/assets/images/landingPage/screenshot/AppScreenshot-${i18n.language}.png`} alt="app-screenshot" className="third-part-screenshot"/>
                    <AccessAppButton white small={props.isSmallScreen}/>
                </Flex>
            </Flex>
            <Flex className="community-path-image" align="center" justify="center">
                {getCommunityPathImage()}
            </Flex>
        </Flex>
    )
}

export default HomePageThirdPart;