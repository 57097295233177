import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

/**
 * Fetches the weight data from the pet.
 * @param {string} accessToken - The access token for authorization.
 * @returns {Promise<any>} - The response data or null in case of an error.
 */
const getWeightFromPet = async (accessToken: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/weight`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return null;
    }
}

/**
 * Creates a new weight entry.
 * @param {string} accessToken - The access token for authorization.
 * @param {number} weight - The weight value.
 * @param {string} date - The date of the weight entry.
 * @returns {Promise<any>} - The response data.
 */
const createWeight = async (accessToken: string, weight: number, date: string) => {
  try {
      const data = JSON.stringify({weight, created_at: date}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/weight `,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
      return response.data
  } catch (error: any) {
      throw error;
  }
}

/**
 * Deletes a weight entry.
 * @param {string} accessToken - The access token for authorization.
 * @param {string} id - The ID of the weight entry.
 * @returns {Promise<void>}
 */
const deleteWeight = async (accessToken: string, id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/weight/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

/**
 * Updates a weight entry.
 * @param {string} accessToken - The access token for authorization.
 * @param {number} weight - The weight value.
 * @param {string} date - The date of the weight entry.
 * @param {number} id - The ID of the weight entry.
 * @returns {Promise<void>}
 */
const updateWeight = async (accessToken: string, weight: number, date: string, id: number) => {
  try {
    const data = JSON.stringify({weight, created_at: date, id}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/weight `,
      data,
      {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

export {
  getWeightFromPet,
  createWeight,
  deleteWeight,
  updateWeight
}