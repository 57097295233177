import { Link } from "react-router-dom";

import "./AccessAppButton.css";
import { Flex } from "antd";

function AccessAppButton(props) {
    const color = props.white ? "white" : "blue";
    const inverted = props.white ? "blue" : "white";
    const size = props.small ? "small" : "";

    return (
        <Link to="/application">
            <button className={`access-button ${color} ${size}`}>
                <Flex align="center" justify="center" gap="small">
                    <img src={`/assets/icons/click-${inverted}.png`} alt="Click" style={{height: (props.small ? "27px" : "35px")}}/>
                    Application
                </Flex>
            </button>
        </Link>
    )
}

export default AccessAppButton;